import React, { ReactElement, useState } from 'react';
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import CheckboxList from 'components/CheckboxList/CheckboxList';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import { Identifier } from 'types/Identifier';

import './FilterDropDown.scss';

export interface Props {
    filterItems: Identifier[];
    // setVisibilityFilter: any,
    handleTimeFilter: (event: any) => void;
}

const FilterDropDown = ({ filterItems, handleTimeFilter }: Props): ReactElement => {
    const [open, setOpen] = useState(false);
    const [timeOpen, setTimeOpen] = useState(false);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleTimeClick = () => {
        setTimeOpen(!timeOpen);
    };
    return (
        <div className="FilterDropDown">
            <List component="nav">
                <ListItem className="FilterDropDown__nav" button onClick={handleClick}>
                    <ListItemText className="FilterDropDown__nav-label" inset primary="Entity" />
                    {open ? (
                        <ExpandLess className="FilterDropDown__nav-icon" />
                    ) : (
                        <ExpandMore className="FilterDropDown__nav-icon" />
                    )}
                </ListItem>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <CheckboxList filterItems={filterItems} />
                </Collapse>
                <ListItem className="FilterDropDown__nav" button onClick={handleTimeClick}>
                    <ListItemText className="FilterDropDown__nav-label" inset primary="Time Filter" />
                    {timeOpen ? (
                        <ExpandLess className="FilterDropDown__nav-icon" />
                    ) : (
                        <ExpandMore className="FilterDropDown__nav-icon" />
                    )}
                </ListItem>
                <Collapse in={timeOpen} timeout="auto" unmountOnExit>
                    <DateTimePicker handleTimeFilter={handleTimeFilter} />
                </Collapse>
            </List>
        </div>
    );
};
export default FilterDropDown;
