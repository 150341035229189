import React, { ReactElement, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import dayjs from 'dayjs';
import _ from 'lodash';

import { selectEndDate, selectStartDate, setViewEndDate, setViewStartDate } from 'app/viewSlice';
import { selectObservations } from 'app/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import './DateTimePicker.scss';

interface Props {
    handleTimeFilter: (event: any) => void;
}

const DateTimePicker = ({ handleTimeFilter }: Props): ReactElement => {
    const dispatch = useAppDispatch();
    const appObservations = useAppSelector(selectObservations);
    const appStartDate = useAppSelector(selectStartDate);
    const appEndDate = useAppSelector(selectEndDate);
    const sortedObservations = _.orderBy(appObservations, ['sensorTimestamp'], ['asc']);
    const selectedStartDate = appStartDate || sortedObservations[0].sensorTimestamp;
    const selectedEndDate = appEndDate || _.last(sortedObservations)?.sensorTimestamp;

    useEffect(() => {
        dispatch(setViewStartDate(selectedStartDate));
        dispatch(setViewEndDate(selectedEndDate || dayjs().format('YYYY-MM-DD[T]HH:mm:ss')));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <form className="DateTimePicker" noValidate>
            <TextField
                id="startDate"
                label="Start Date"
                type="datetime-local"
                defaultValue={selectedStartDate}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    name: 'startFilter',
                }}
                onChange={handleTimeFilter}
            />
            <TextField
                id="endDate"
                label="End Date"
                type="datetime-local"
                defaultValue={selectedEndDate}
                InputLabelProps={{
                    shrink: true,
                }}
                inputProps={{
                    name: 'endFilter',
                }}
                onChange={handleTimeFilter}
            />
        </form>
    );
};
export default DateTimePicker;
