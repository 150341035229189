import React, { ReactElement, useEffect, useState } from 'react';
import { InputLabel, MenuItem, FormControl, Select, TextField, AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { usePrevious } from 'react-use';
import _ from 'lodash';

import { Activity } from 'types/Activity';
import { Instance } from 'types/Instance';
import { Observation } from 'types/Observation';
import {
    selectEntityModels,
    selectEntities,
    selectEntitiesGroupedByLabel,
    selectExpectedActivities,
    selectLocations,
    selectLocationsGroupedByLabel,
    selectModelsGroupedByLabel,
    selectPlotFilterSelectionVisibleEntityIds,
    selectPlotFilterSelectionVisibleLocationIds,
    selectPlotFilterSelectionVisibleModelIds,
    selectInstances,
    selectDemoOn,
    selectScenario,
} from 'app/appSlice';
import {
    selectPlotControls,
    selectPlotFilterSelectedEntities,
    selectPlotFilterSelectedLocations,
    selectPlotFilterSelectedModels,
    setPlotControls,
    setPlotFilterSelectedEntities,
    setPlotFilterSelectedLocations,
    setPlotFilterSelectedModels,
    setPlotState,
} from 'app/viewSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import './ControlPanel.scss';
import { PlotControls } from 'types/Plot';

const chartTypes = [
    {
        type: 'scatter',
        name: 'Scatter Plot',
        inputs: ['x', 'y'],
    },
    {
        type: 'box',
        name: 'Box Plot',
        inputs: ['values', 'x'],
    },
    {
        type: 'bar',
        name: 'Bar Chart',
        inputs: ['values', 'x'],
    },
];

const variables = [
    {
        name: 'Entity',
        value: 'entity',
    },
    {
        name: 'Model',
        value: 'entityModel',
    },
    {
        name: 'Location',
        value: 'location',
    },
    {
        name: 'Sub Entities',
        value: 'subevent',
    },
    {
        name: 'Observed Time',
        value: 'time',
    },
    {
        value: 'timeToEvent',
        name: 'Time to Event',
    },
];

const TabContainer = ({ children }: any): ReactElement => (
    <Typography component="div" style={{ padding: 8 * 3 }}>
        {children}
    </Typography>
);

const ControlPanel = (): ReactElement => {
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    const dispatch = useAppDispatch();
    const [csvData, setCsvData] = useState<any>([]);
    const appPlotControls: PlotControls = useAppSelector(selectPlotControls);
    const appScenario = useAppSelector(selectScenario);
    const appExpectedActivities = useAppSelector(selectExpectedActivities);
    const appInstances = useAppSelector(selectInstances);
    const appEntities = useAppSelector(selectEntities);
    const appDemo = useAppSelector(selectDemoOn);
    const appModels = useAppSelector(selectEntityModels);
    const appLocations = useAppSelector(selectLocations);
    const appPlotFilterSelectedModels = useAppSelector(selectPlotFilterSelectedModels);
    const appPlotFilterSelectedEntities = useAppSelector(selectPlotFilterSelectedEntities);
    const appPlotFilterSelectedLocations = useAppSelector(selectPlotFilterSelectedLocations);
    const appModelsGroupedByLabel = useAppSelector(selectModelsGroupedByLabel);
    const appEntitiesGroupedByLabel = useAppSelector(selectEntitiesGroupedByLabel);
    const appLocationsGroupedByLabel = useAppSelector(selectLocationsGroupedByLabel);
    const appPlotFilterSelectionVisibleModelIds = useAppSelector(selectPlotFilterSelectionVisibleModelIds);
    const appPlotFilterSelectionVisibleEntityIds = useAppSelector(selectPlotFilterSelectionVisibleEntityIds);
    const appPlotFilterSelectionVisibleLocationIds = useAppSelector(selectPlotFilterSelectionVisibleLocationIds);

    const rebuildCSV = (): any => {
        const expectedActivities: any[] = [...appExpectedActivities];
        let result: any[] = _.map(appInstances, (instance: Instance) => {
            // Pre-included instances have the id, newly added have the entity name
            const entity = _.find(appEntities, { id: instance.entityId });
            const model = _.find(appModels, { id: instance.model });
            return _.map(instance.observations, (ob: Observation) => {
                const observation = { ...ob };
                observation.timeToEvent = 0;
                const location = _.find(appLocations, { id: observation.locationId });
                const activity = _.find(model.expectedActivities, (act: Activity) => {
                    const newActivity = { ...act };
                    let data;
                    _.forEach(expectedActivities, (a: Activity) => {
                        if (a.id === newActivity.id) {
                            newActivity.order = a.order;
                        }
                    });
                    if (newActivity.id === observation.activityId) {
                        observation.timeToEvent = newActivity.timeToEvent;
                        data = newActivity;
                    }
                    return data;
                });
                if (
                    appPlotFilterSelectedModels.length === 0 ||
                    (model ? appPlotFilterSelectedModels.includes(model.id) : false)
                ) {
                    if (
                        appPlotFilterSelectedEntities.length === 0 ||
                        appPlotFilterSelectedEntities.includes(entity?.id)
                    ) {
                        if (
                            appPlotFilterSelectedLocations.length === 0 ||
                            appPlotFilterSelectedLocations.includes(location.id)
                        ) {
                            return {
                                id: observation.id,
                                entity: entity?.name,
                                entityModel: model?.name,
                                location: location.name,
                                subevent: activity,
                                time: observation.sensorTimestamp,
                                timeToEvent: observation.timeToEvent,
                            };
                        }
                    }
                }
                return null;
            });
        });
        result = result.reduce((acc, val) => acc.concat(val), []).filter((val: any) => val !== null);
        return result;
    };

    const buildValues = (variable: any, groupColumn: any, groupValue: any): any => {
        if (variable) {
            let filteredData = [...csvData];
            if (groupColumn && groupValue !== 'all') {
                filteredData = _.filter(filteredData, (x: any) => x[groupColumn] === groupValue);
            }
            let data = _.map(filteredData, (x: any) => x[variable]);
            if (variable === 'subevent') {
                data = _.map(filteredData, (x: any) => x[variable].activityType);
            }
            return data;
        }
        return [];
    };

    const handleChange = (event: any) => {
        const newPlotControls: any = { ...appPlotControls };
        newPlotControls[event.target.name] = event.target.value;
        let groups: string[] = ['all'];
        let groupColumn = '';
        if (event?.target?.name === 'groupBy' || newPlotControls.groupBy) {
            groupColumn = event?.target?.name === 'groupBy' ? event.target.value : newPlotControls.groupBy;
            groups = _.uniq(_.map(csvData, (x: any) => x[groupColumn]));
        }
        const typeValue = event.target.name === 'type' ? event.target.value : newPlotControls.type;
        const xValue = event.target.name === 'xaxis' ? event.target.value : newPlotControls.xaxis;
        const yValue = event.target.name === 'yaxis' ? event.target.value : newPlotControls.yaxis;
        if (!newPlotControls.startTime) {
            newPlotControls.startTime = _.first(_.sortBy(_.map(csvData, 'time')));
        }
        if (!newPlotControls.endTime) {
            newPlotControls.endTime = _.last(_.sortBy(_.map(csvData, 'time')));
        }
        dispatch(setPlotControls(newPlotControls));
        dispatch(
            setPlotState(
                _.map(groups, (group: any) => ({
                    type: typeValue,
                    x: buildValues(xValue, groupColumn, group),
                    y: buildValues(yValue, groupColumn, group),
                    name: group,
                    boxpoints: 'all',
                    mode: 'markers',
                }))
            )
        );
    };

    const handleDemo = () => {
        const groups: string[] = ['all'];
        const groupColumn = '';
        dispatch(
            setPlotState(
                _.map(groups, (group: any) => ({
                    type: appPlotControls.type,
                    x: buildValues('entityModel', groupColumn, group),
                    y: buildValues('time', groupColumn, group),
                    name: group,
                    boxpoints: 'all',
                    mode: 'markers',
                }))
            )
        );
    };

    const handleStartFilterChange = (event: any): void => {
        const startDate = dayjs(event.target.value);
        const endDate = dayjs(appPlotControls.endTime);
        if (startDate.isSameOrBefore(endDate)) {
            dispatch(setPlotControls({ ...appPlotControls, startTime: startDate.format('YYYY-MM-DD[T]HH:mm:ss') }));
            const data = rebuildCSV();
            setCsvData(
                _.filter(data, (element: any) => {
                    const dataDate: any = dayjs(element.time);
                    return dataDate.isSameOrAfter(startDate) && dataDate.isSameOrBefore(endDate);
                })
            );
        } else {
            alert('Start date must be before end date'); // eslint-disable-line no-alert
            event.target.value = appPlotControls.startTime; // eslint-disable-line no-param-reassign
        }
    };

    const handleEndFilterChange = (event: any): void => {
        const startDate = dayjs(appPlotControls.startTime);
        const endDate = dayjs(event.target.value);
        if (startDate.isSameOrBefore(endDate)) {
            dispatch(setPlotControls({ ...appPlotControls, endTime: endDate.format('YYYY-MM-DD[T]HH:mm:ss') }));
            const data = rebuildCSV();
            setCsvData(
                _.filter(data, (element: any) => {
                    const dataDate: any = dayjs(element.time);
                    return dataDate.isSameOrAfter(startDate) && dataDate.isSameOrBefore(endDate);
                })
            );
        } else {
            alert('Start date must be before end date'); // eslint-disable-line no-alert
            event.target.value = appPlotControls.endTime; // eslint-disable-line no-param-reassign
        }
    };

    useEffect(() => {
        const selectedModelGroups = _.filter(appModelsGroupedByLabel, (group: any) =>
            _.includes(appPlotControls.modelFilters, group.label)
        );
        let selectedModelIds: any[] = [];
        _.forEach(selectedModelGroups, (group: any) => {
            selectedModelIds = selectedModelIds.concat(group.value);
        });
        const promise = new Promise((resolve) => {
            dispatch(setPlotFilterSelectedModels(selectedModelIds));
            resolve(true);
        });
        promise.then(() => {
            setCsvData(rebuildCSV());
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appPlotControls.modelFilters]);

    useEffect(() => {
        setCsvData(rebuildCSV());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appPlotFilterSelectedEntities, appPlotFilterSelectedLocations, appPlotFilterSelectedModels]);

    useEffect(() => {
        const selectedEntityGroups = _.filter(appEntitiesGroupedByLabel, (group: any) =>
            _.includes(appPlotControls.entityFilters, group.label)
        );
        let selectedEntityIds: any[] = [];
        _.forEach(selectedEntityGroups, (group: any) => {
            selectedEntityIds = selectedEntityIds.concat(group.value);
        });
        const promise = new Promise((resolve) => {
            dispatch(setPlotFilterSelectedEntities(selectedEntityIds));
            resolve(true);
        });
        promise.then(
            () => {
                setCsvData(rebuildCSV());
            },
            () => {}
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appPlotControls.entityFilters]);

    useEffect(() => {
        const selectedLocationGroups = _.filter(appLocationsGroupedByLabel, (group: any) =>
            _.includes(appPlotControls.locationFilters, group.label)
        );
        let selectedLocationIds: any[] = [];
        _.forEach(selectedLocationGroups, (group: any) => {
            selectedLocationIds = selectedLocationIds.concat(group.value);
        });
        const promise = new Promise((resolve) => {
            dispatch(setPlotFilterSelectedLocations(selectedLocationIds));
            resolve(true);
        });
        promise.then(
            () => {
                setCsvData(rebuildCSV());
            },
            () => {}
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appPlotControls.locationFilters]);

    const prevCsvData = usePrevious(csvData);
    useEffect(() => {
        if (!_.isEqual(prevCsvData, csvData)) {
            handleChange({ target: { name: '', value: '' } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [csvData]);

    useEffect(() => {
        if (appDemo && (appPlotControls.xaxis === '' || appPlotControls.yaxis === '')) {
            dispatch(
                setPlotControls({
                    xaxis: 'entityModel',
                    yaxis: 'time',
                    type: 'box',
                    groupBy: '',
                    tabSelect: 0,
                    modelFilters: [],
                    entityFilters: [],
                    locationFilters: [],
                    startTime: '',
                    endTime: '',
                })
            );
            handleDemo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appDemo]);

    useEffect(() => {
        const data = rebuildCSV();
        setCsvData(_.orderBy(data, ['subevent.order'], ['asc']));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appInstances]);

    const prevAppScenario = usePrevious(appScenario);
    useEffect(() => {
        if (prevAppScenario && !_.isEqual(prevAppScenario, appScenario)) {
            dispatch(
                setPlotControls({
                    xaxis: '',
                    yaxis: '',
                    type: 'box',
                    groupBy: '',
                    tabSelect: 0,
                    modelFilters: [],
                    entityFilters: [],
                    locationFilters: [],
                    startTime: '',
                    endTime: '',
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appScenario]);

    return (
        <aside>
            <form className="ControlPanel" id="controlPanel">
                <FormControl className="ControlPanel__plotCPformControl-top">
                    <InputLabel shrink htmlFor="type">
                        Type
                    </InputLabel>
                    <Select
                        value={appPlotControls.type}
                        onChange={handleChange}
                        inputProps={{
                            name: 'type',
                            id: 'type',
                        }}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        {chartTypes.map((v) => (
                            <MenuItem key={v.type} value={v.type}>
                                {v.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <div className="ControlPanel__axis">
                    <FormControl className="ControlPanel__plotCPformControl">
                        <InputLabel shrink htmlFor="xaxis">
                            X Axis
                        </InputLabel>
                        <Select
                            value={appPlotControls.xaxis}
                            onChange={handleChange}
                            inputProps={{
                                name: 'xaxis',
                                id: 'xaxis',
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {variables.slice(0, 4).map((v) => (
                                <MenuItem key={v.value} value={v.value}>
                                    {v.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl className="ControlPanel__plotCPformControl">
                        <InputLabel shrink htmlFor="yaxis">
                            Y Axis
                        </InputLabel>
                        <Select
                            value={appPlotControls.yaxis}
                            onChange={handleChange}
                            inputProps={{
                                name: 'yaxis',
                                id: 'yaxis',
                            }}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {variables.slice(4).map((v) => (
                                <MenuItem key={v.value} value={v.value}>
                                    {v.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </form>
            <div className="ControlsArea__tabs">
                <AppBar position="static" color="default" elevation={0}>
                    <Tabs
                        className="ControlsArea__tabContainer"
                        value={appPlotControls.tabSelect}
                        onChange={(event: any, value: any): void =>
                            dispatch(setPlotControls({ ...appPlotControls, tabSelect: value }))
                        }
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                    >
                        <Tab label="Group" />
                        <Tab label="Filter" />
                    </Tabs>
                </AppBar>
                {appPlotControls.tabSelect === 0 && (
                    <TabContainer>
                        <form>
                            <FormControl className="ControlsArea__tabs-filterFormControl">
                                <InputLabel shrink htmlFor="groupby">
                                    Group By
                                </InputLabel>
                                <Select
                                    value={appPlotControls.groupBy}
                                    onChange={handleChange}
                                    inputProps={{
                                        name: 'groupBy',
                                        id: 'groupBy',
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    {variables.map((v) => (
                                        <MenuItem key={v.value} value={v.value}>
                                            {v.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </form>
                    </TabContainer>
                )}
                {appPlotControls.tabSelect === 1 && (
                    <TabContainer>
                        {appPlotControls.yaxis !== 'timeToEvent' && (
                            <div className="ControlsArea__tabs-timePickerContainer">
                                <TextField
                                    id="datetime-local-start"
                                    label="Start Time"
                                    type="datetime-local"
                                    value={appPlotControls.startTime || ''}
                                    className="ControlsArea__tabs-timePickerContainer-timePicker"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        name: 'startFilter',
                                    }}
                                    onChange={handleStartFilterChange}
                                />
                                <TextField
                                    id="datetime-local-end"
                                    label="End Time"
                                    type="datetime-local"
                                    value={appPlotControls.endTime || ''}
                                    className="ControlsArea__tabs-timePickerContainer-timePicker"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        name: 'endFilter',
                                        id: 'modelFilter',
                                    }}
                                    onChange={handleEndFilterChange}
                                />
                            </div>
                        )}
                        <form className="ControlsArea__tabs-filterSelectsContainer">
                            <FormControl className="ControlsArea__tabs-filterFormControl">
                                <InputLabel shrink htmlFor="model">
                                    Model
                                </InputLabel>
                                <Select
                                    multiple
                                    placeholder="Entity Models"
                                    value={appPlotControls.modelFilters}
                                    inputProps={{
                                        name: 'modelFilter',
                                        id: 'modelFilter',
                                    }}
                                    className="ControlsArea__tabs-filterFormControl-filterSelects"
                                    onChange={(event: any) =>
                                        dispatch(
                                            setPlotControls({ ...appPlotControls, modelFilters: event.target.value })
                                        )
                                    }
                                >
                                    {appModelsGroupedByLabel.map((model: any) => {
                                        let visible = true;
                                        if (model.value.length === 1) {
                                            if (appPlotFilterSelectionVisibleModelIds.includes(model.value[0])) {
                                                visible = true;
                                            } else {
                                                visible = false;
                                            }
                                        }
                                        return (
                                            <MenuItem key={model.label} value={model.label} disabled={!visible}>
                                                {model.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <FormControl className="ControlsArea__tabs-filterFormControl">
                                <InputLabel shrink htmlFor="entity">
                                    Entity
                                </InputLabel>
                                <Select
                                    className="ControlsArea__tabs-filterFormControl-filterSelects"
                                    multiple
                                    placeholder="Entities"
                                    value={appPlotControls.entityFilters}
                                    inputProps={{
                                        name: 'entityFilter',
                                        id: 'entityFilter',
                                    }}
                                    onChange={(event: any) =>
                                        dispatch(
                                            setPlotControls({ ...appPlotControls, entityFilters: event.target.value })
                                        )
                                    }
                                >
                                    {appEntitiesGroupedByLabel.map((entity: any) => {
                                        let visible = true;
                                        entity.value.forEach((id: any) => {
                                            if (appPlotFilterSelectionVisibleEntityIds.includes(id)) {
                                                visible = true;
                                            } else {
                                                visible = false;
                                            }
                                        });
                                        return (
                                            <MenuItem key={entity.label} value={entity.label} disabled={!visible}>
                                                {entity.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                            <br />
                            <FormControl className="ControlsArea__tabs-filterFormControl">
                                <InputLabel shrink htmlFor="location">
                                    Locations
                                </InputLabel>
                                <Select
                                    className="ControlsArea__tabs-filterFormControl-filterSelects"
                                    multiple
                                    placeholder="Locations"
                                    value={appPlotControls.locationFilters}
                                    inputProps={{
                                        name: 'locationFilter',
                                        id: 'locationFilter',
                                    }}
                                    onChange={(event: any) =>
                                        dispatch(
                                            setPlotControls({ ...appPlotControls, locationFilters: event.target.value })
                                        )
                                    }
                                >
                                    {appLocationsGroupedByLabel.map((kLoc: any) => {
                                        let visible = false;
                                        kLoc.value.forEach((id: any) => {
                                            if (appPlotFilterSelectionVisibleLocationIds.includes(id)) {
                                                visible = true;
                                            }
                                        });
                                        return (
                                            <MenuItem key={kLoc.label} value={kLoc.label} disabled={!visible}>
                                                {kLoc.label}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </form>
                    </TabContainer>
                )}
            </div>
        </aside>
    );
};

export default ControlPanel;
