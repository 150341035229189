import React, { ReactElement, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
    AppBar,
    Button,
    FormControl,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { HelpOutlineOutlined, Laptop } from '@material-ui/icons';
import { trackPromise } from 'react-promise-tracker';
import qs from 'query-string';

import { selectDemoOn, fetchData, setHelpOpen, setScenario, selectScenario, setDemoOn } from 'app/appSlice';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import Demo from 'components/Demo/Demo';
import Logo from 'images/Logo.svg';

import './NavBar.scss';

const NavBar = (): ReactElement => {
    const location = useLocation();
    const queryParams = qs.parse(location.search);
    const dispatch = useAppDispatch();
    const appScenario = useAppSelector(selectScenario);
    const appDemo = useAppSelector(selectDemoOn);
    const [orgClassname, setOrgClassname] = useState<string>('');
    const [analysisClassname, setAnalysisClassname] = useState<string>('');
    const history = useHistory();

    history.listen((historyLocation: any) => {
        setOrgClassname(historyLocation.pathname === '/' ? '-selected' : '');
        setAnalysisClassname(historyLocation.pathname === '/data-analysis' ? '-selected' : '');
        const historyQueryParams = qs.parse(historyLocation.search);
        if (historyQueryParams.id) {
            dispatch(setScenario(historyQueryParams.id as string));
        }
    });

    useEffect(() => {
        trackPromise(dispatch(fetchData(appScenario)));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appScenario]);

    useEffect(() => {
        setOrgClassname(location.pathname === '/' ? '-selected' : '');
        setAnalysisClassname(location.pathname === '/data-analysis' ? '-selected' : '');
        dispatch(setScenario((queryParams.id as string) || '1c7f8f61-bd64-422d-a8a1-396c31470125'));
        dispatch(setHelpOpen(typeof queryParams.topic !== 'undefined'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="NavBar">
            <AppBar position="static" color="primary">
                <Toolbar>
                    <div className="NavBar__controls">
                        <Link className="NavBar__controls" to="/">
                            <img src={Logo} alt="Mile 2" className="NavBar__logo" />
                        </Link>
                        <Typography variant="h1" color="inherit" align="right" className="NavBar__title">
                            KNOWLEDGE DISCOVERY
                        </Typography>
                        <List className="NavBar__pages" component="nav" id="pageSelector">
                            <ListItem
                                className={`NavBar__pages-DataOrgWork${orgClassname}`}
                                button
                                component={Link}
                                to={`/${queryParams ? `?${qs.stringify(queryParams)}` : ''}`}
                            >
                                <ListItemText primary="Data Org Workspace" />
                            </ListItem>
                            <ListItem
                                className={`NavBar__pages-DataAnalysis${analysisClassname}`}
                                button
                                component={Link}
                                to={`/data-analysis${queryParams ? `?${qs.stringify(queryParams)}` : ''}`}
                            >
                                <ListItemText primary="Data Analysis" />
                            </ListItem>
                        </List>
                    </div>
                    <div className="NavBar__help">
                        {(process.env.NODE_ENV === 'development' || queryParams.admin === 'true') && (
                            <FormControl>
                                <Select
                                    value={appScenario}
                                    onChange={(event: any) => {
                                        queryParams.id = event.target.value;
                                        history.push({
                                            pathname: location.pathname,
                                            search: qs.stringify(queryParams),
                                        });
                                        dispatch(setScenario(event.target.value));
                                    }}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                >
                                    <MenuItem value="" disabled>
                                        Scenario
                                    </MenuItem>
                                    <MenuItem value="1c7f8f61-bd64-422d-a8a1-396c31470125">Bakery</MenuItem>
                                    <MenuItem value="291cdc4e-ca7c-43fb-a511-02862f53052c">Medical</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                        <Button
                            color="inherit"
                            className="Help__demoButton"
                            onClick={() => {
                                dispatch(setDemoOn(true));
                            }}
                        >
                            <Laptop className="NavBar__help-helpButton" />
                            Demo
                        </Button>
                        <Button
                            color="inherit"
                            aria-label="Menu"
                            onClick={() => dispatch(setHelpOpen(true))}
                            id="helpButton"
                        >
                            <HelpOutlineOutlined className="NavBar__help-helpButton" />
                            HELP
                        </Button>
                    </div>
                </Toolbar>
            </AppBar>
            {appDemo && <Demo />}
        </div>
    );
};
export default NavBar;
