import React, { ChangeEvent, ReactElement, useEffect } from 'react';
import _ from 'lodash';
import { Badge, Switch, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import { usePrevious } from 'react-use';

import { Activity } from 'types/Activity';
import { Entity } from 'types/Entity';
import { Model } from 'types/Model';
import { ModelWithInstance } from 'types/ModelWithInstance';
import { Observation } from 'types/Observation';
import {
    selectEntityModels,
    selectModelsWithInstances,
    setEntityModels,
    selectInstances,
    setComparableModels,
    selectEntities,
    selectDemoOn,
    selectScenario,
} from 'app/appSlice';
import { selectSelected, selectExpandedModelIds, setExpandedModelIds } from 'app/viewSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Info from 'components/Info/Info';

import './ModelsList.scss';

const ModelsList = (): ReactElement => {
    const dispatch = useAppDispatch();
    const appEntities = useAppSelector(selectEntities);
    const appEntityModels = useAppSelector(selectEntityModels);
    const appInstances = useAppSelector(selectInstances);
    const appSelected = useAppSelector(selectSelected);
    const appModelsWithInstances = useAppSelector(selectModelsWithInstances);
    const appScenario = useAppSelector(selectScenario);
    const appDemo = useAppSelector(selectDemoOn);
    const appExpandedModelIds = useAppSelector(selectExpandedModelIds);

    const handleChange = (event: ChangeEvent<any>) => {
        const id: string = event.target.value || '';
        const currentIndex = _.indexOf(appExpandedModelIds, id);
        const newExpandedModelIds = [...appExpandedModelIds];
        if (currentIndex === -1) {
            newExpandedModelIds.push(id);
        } else {
            newExpandedModelIds.splice(currentIndex, 1);
        }
        dispatch(setExpandedModelIds(newExpandedModelIds));
    };

    useEffect(() => {
        if (appDemo && !appExpandedModelIds.length) {
            dispatch(setExpandedModelIds([appModelsWithInstances[1]?.id]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appDemo]);

    const prevAppScenario = usePrevious(appScenario);
    useEffect(() => {
        if (prevAppScenario && !_.isEqual(prevAppScenario, appScenario)) {
            dispatch(setExpandedModelIds([]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appScenario]);

    const tableRowEls: ReactElement[] = _.reduce(
        appModelsWithInstances,
        (result: ReactElement[], model: ModelWithInstance): any => {
            if (model.name !== 'Unassigned') {
                const nameCount = _.size(_.filter(appModelsWithInstances, { name: model.name }));
                const modelEntity: any = _.find(appEntities, (e: Entity) => _.find(e.models, { id: model.id }));
                const modelName = nameCount > 1 ? `${model.name} (${modelEntity?.name})` : model.name;
                const checked = _.includes(appExpandedModelIds, model.id);
                result.push(
                    <TableRow hover role="row" tabIndex={-1} key={model.id}>
                        <TableCell>
                            <div className="ModelsList__model" id={`toggle-${model.id}`}>
                                <Switch
                                    onChange={handleChange}
                                    value={model.id}
                                    color="primary"
                                    inputProps={{
                                        'aria-label': 'Switch',
                                    }}
                                    checked={checked}
                                />
                                <div className="ModelsList__model-name">{modelName}</div>
                                {model.matching.observations.length > 0 && (
                                    <div className="ModelsList__model-badge">
                                        <Badge
                                            badgeContent={model.matching.observations.length || '0'}
                                            color="secondary"
                                        >
                                            <span />
                                        </Badge>
                                    </div>
                                )}
                            </div>
                        </TableCell>
                        <TableCell>
                            <div className="ModelsList__instances">
                                {appInstances.filter((i) => i.model === model.id).length}
                            </div>
                        </TableCell>
                    </TableRow>
                );
            }
            return result;
        },
        []
    );

    // when models are selected or deselected, update pairing data between observations and activities
    const prevAppSelected = usePrevious(appSelected);
    useEffect(() => {
        const newAppEntityModels = _.cloneDeep(appEntityModels);
        // pair observations with activities
        if (appSelected.length) {
            _.forEach(appSelected, (observation: Observation) => {
                _.forEach(newAppEntityModels, (model: Model, idx: number) => {
                    const newModel = _.cloneDeep(model);
                    const activityIdx = _.findIndex(newModel.expectedActivities, { id: observation.activityId });
                    if (activityIdx > -1) {
                        newModel.expectedActivities[activityIdx].observation = observation;
                        newModel.matching.isMatching = true;
                        if (_.findIndex(newModel.matching.observations, observation) < 0) {
                            newModel.matching.observations.push(observation);
                        }
                    } else {
                        newModel.matching.isMatching = false;
                        newModel.matching.observations = _.filter(
                            newModel.matching.observations,
                            (o: Observation) => o.id !== observation.id
                        );
                    }
                    newAppEntityModels[idx] = newModel;
                });
            });
        } else {
            // nothing is selected, so remove all pairing data
            _.forEach(newAppEntityModels, (model: Model, idx: number) => {
                const newModel = _.cloneDeep(model);
                newModel.matching = {
                    isMatching: false,
                    observations: [],
                };
                newAppEntityModels[idx] = newModel;
            });
        }
        // if something has been deselected, remove pairing data from expectedActivity
        const deselected = prevAppSelected ? _.difference(prevAppSelected, appSelected) : null;
        if (deselected && deselected.length) {
            _.forEach(newAppEntityModels, (model: Model, idx: number) => {
                const newModel = _.cloneDeep(model);

                // remove from expected activities
                const removeIdx = _.findIndex(newModel.expectedActivities, (activity: Activity) =>
                    activity.observation ? activity.observation.id === deselected[0].id : false
                );

                if (removeIdx > -1) {
                    newModel.expectedActivities[removeIdx].observation = null;
                }

                // remove from observation
                const removeObservationIdx = _.findIndex(newModel.matching.observations, { id: deselected[0].id });
                if (removeObservationIdx > -1) {
                    newModel.matching.observations.splice(removeObservationIdx, 1);
                }
                newAppEntityModels[idx] = newModel;
            });
        }

        dispatch(setEntityModels(newAppEntityModels));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSelected]);

    // update expandable models
    const prevAppModelsWithInstances = usePrevious(appModelsWithInstances);
    const prevExpandedModelIds = usePrevious(appExpandedModelIds);
    useEffect(() => {
        if (
            !_.isEqual(prevAppModelsWithInstances, appModelsWithInstances) ||
            !_.isEqual(prevExpandedModelIds, appExpandedModelIds)
        ) {
            dispatch(
                setComparableModels(_.map(appExpandedModelIds, (id: string) => _.find(appModelsWithInstances, { id })))
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appModelsWithInstances, appExpandedModelIds]);

    return (
        <div className="ModelsList">
            <div className="Flex-layout">
                <Typography variant="subtitle1" component="h2" className="ModelsList__title">
                    Expand Models
                </Typography>
                <Info title="Toggle one or more models to inspect how closely the model's events align with expectations." />
            </div>
            <Table aria-label="tableTitle">
                <TableHead>
                    <TableRow>
                        <TableCell width="60%">Model Name</TableCell>
                        <TableCell width="40%">
                            <div className="Flex-layout">
                                <div>Number of Instances</div>
                                <Info title="Different instances of the same model in which different observations have been assigned" />
                            </div>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className="TableBody">{tableRowEls}</TableBody>
            </Table>
        </div>
    );
};

export default ModelsList;
