/* eslint-disable */
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Entity } from 'types/Entity';
import { Model } from 'types/Model';
import { Observation } from 'types/Observation';
import { RootState } from 'app/store';
import { ModelWithInstance } from 'types/ModelWithInstance';
import { Identifier } from 'types/Identifier';
import { Instance } from 'types/Instance';
import { Activity } from 'types/Activity';

interface SliceState {
    entityModels: Model[];
    expectedActivities: Activity[];
    observations: any;
    entities: Entity[];
    locations: Identifier[];
    statuses: any;
    instances: Instance[];
    comparableModels: ModelWithInstance[];
    helpOpen: boolean;
    search: any;
    helpExpand: boolean;
    demoOn: boolean;
    scenario: string;
}

const initialState: SliceState = {
    entityModels: [],
    expectedActivities: [],
    observations: [],
    entities: [],
    locations: [],
    statuses: [],
    instances: [],
    comparableModels: [],
    helpOpen: false,
    search: '',
    helpExpand: false,
    demoOn: false,
    scenario: '1c7f8f61-bd64-422d-a8a1-396c31470125',
};

export const fetchData = createAsyncThunk('data', async (scenario: string): Promise<any> => {
    let scenarioName = 'bakery';
    if (scenario === '1c7f8f61-bd64-422d-a8a1-396c31470125') {
        scenarioName = 'bakery';
    } else if (scenario === '291cdc4e-ca7c-43fb-a511-02862f53052c') {
        scenarioName = 'medical';
    }
    const response = await fetch(`/api/scenarios/${scenarioName}`);
    return response.json();
});

const getGroupedByLabel = (arr: any): any[] => {
    let grouped: any[] = [];
    let iterIndex = -1;
    arr.forEach((item: any) => {
        iterIndex = grouped.map((gi) => gi.label).indexOf(item.name);
        if (iterIndex === -1) {
            grouped.push({ label: item.name, value: [item.id] });
        } else {
            grouped = [
                ...grouped.slice(0, iterIndex),
                { label: grouped[iterIndex].label, value: [...grouped[iterIndex].value, item.id] },
                ...grouped.slice(iterIndex + 1),
            ];
        }
    });
    return grouped;
};

/**
 * Returns array of entity ids matching location ids supplied.
 * @param {*} entities - model as appearing in json object
 * @param {*} locations - list of location ids
 */
const getEntityIdsMatchingLocations = (e: any, l: any) => {
    const filteredEntities = e.filter((entity: any) => {
        let flag = false;
        _.forEach(l, (entityLocation) => {
            entity.locations.forEach((location: any) => {
                if (entityLocation.id === location.id) {
                    flag = true;
                } else {
                    flag = false;
                }
            });
        });

        return flag;
    });
    return filteredEntities.map((entity: any) => entity.id);
};

const removeDuplicates = (array: any) => {
    const uniqueArray: any[] = [];
    array.forEach((item: any) => {
        if (uniqueArray.indexOf(item) === -1) {
            uniqueArray.push(item);
        }
    });
    return uniqueArray;
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        saveState: (state, action: PayloadAction<SliceState>) => ({
            ...state,
            app: {
                entityModels: action.payload.entityModels,
                expectedActivities: action.payload.expectedActivities,
                observations: action.payload.observations,
                entities: action.payload.entities,
                locations: action.payload.locations,
                statuses: action.payload.statuses,
                comparableModels: action.payload.comparableModels,
                helpOpen: action.payload.helpOpen,
                demoOn: action.payload.demoOn,
                scenario: action.payload.scenario,
            },
        }),
        addObservations: (state, action: PayloadAction<Observation[]>) => {
            const observations = _.cloneDeep(state.observations);
            const newObservations = observations.concat(action.payload);
            return {
                ...state,
                observations: newObservations,
            };
        },
        setExpectedActivities: (state, action: PayloadAction<Activity[]>) => ({
            ...state,
            expectedActivities: action.payload,
        }),
        setEntityModels: (state, action: PayloadAction<Model[]>) => ({
            ...state,
            entityModels: action.payload,
        }),
        addInstance: (state, action: PayloadAction<Instance>) => ({
            ...state,
            instances: [...state.instances, action.payload],
        }),
        deleteInstance: (state, action: PayloadAction<Instance>) => ({
            ...state,
            instances: _.filter(state.instances, (instance: Instance) => instance.id !== action.payload.id),
        }),
        editInstance: (state, action: PayloadAction<Instance>) => ({
            ...state,
            instances: _.map(state.instances, (instance: Instance) => {
                if (instance.id === action.payload.id) {
                    return action.payload;
                }
                return instance;
            }),
        }),
        setInstanceEntity: (state, action: PayloadAction<Entity>) => ({
            ...state,
            instances: _.map(state.instances, (instance: Instance) => {
                if (instance.id === action.payload.id) {
                    return { ...instance, entityId: action.payload.id };
                }
                return instance;
            }),
        }),
        linkObservationToInstance: (state, action: PayloadAction<any>) => ({
            ...state,
            instances: _.map(state.instances, (instance: Instance) => {
                if (instance.id === action.payload.instanceId) {
                    return { ...instance, observations: [...instance.observations, action.payload.observationId] };
                }
                return instance;
            }),
        }),
        removeObservationFromInstance: (state, action: PayloadAction<any>) => ({
            ...state,
            instances: _.map(state.instances, (instance: Instance) => {
                if (instance.id === action.payload.instanceId) {
                    return {
                        ...instance,
                        observations: _.filter(
                            instance.observations,
                            (observationId) => observationId !== action.payload.observationId
                        ),
                    };
                }
                return instance;
            }),
        }),
        linkInstanceToModel: (state, action: PayloadAction<any>) => ({
            ...state,
            instances: _.map(state.instances, (instance: Instance) => {
                if (instance.id === action.payload.instanceId) {
                    return { ...instance, model: action.payload.modelId };
                }
                return instance;
            }),
        }),
        removeModelFromInstance: (state, action: PayloadAction<number>) => ({
            ...state,
            instances: _.map(state.instances, (instance: Instance) => {
                if (instance.id === action.payload) {
                    return { ...instance, model: -1 };
                }
                return instance;
            }),
        }),
        setComparableModels: (state, action: PayloadAction<ModelWithInstance[]>) => ({
            ...state,
            comparableModels: action.payload,
        }),
        setHelpOpen: (state, action: PayloadAction<boolean>) => ({
            ...state,
            helpOpen: action.payload,
        }),
        setSearch: (state, action: PayloadAction<any>) => ({
            ...state,
            search: action.payload,
        }),
        setHelpExpand: (state, action: PayloadAction<boolean>) => ({
            ...state,
            helpExpand: action.payload,
        }),
        setDemoOn: (state, action: PayloadAction<boolean>) => ({
            ...state,
            demoOn: action.payload,
        }),
        setScenario: (state, action: PayloadAction<string>) => ({ ...state, scenario: action.payload }),
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchData.fulfilled.type]: (state, action) => {
            state.entities = action.payload.scenario.entities;
            state.entityModels = action.payload.scenario.entityModels;
            state.expectedActivities = action.payload.scenario.expectedActivities;
            state.locations = action.payload.scenario.locations;
            state.observations = action.payload.scenario.observations;
            state.statuses = action.payload.scenario.statuses;
            state.instances = action.payload.scenario.instances;
            state.comparableModels = action.payload.scenario.comparableModels;
        },
    },
});

// actions
export const {
    saveState,
    addObservations,
    setExpectedActivities,
    setEntityModels,
    addInstance,
    deleteInstance,
    editInstance,
    setInstanceEntity,
    linkObservationToInstance,
    removeObservationFromInstance,
    linkInstanceToModel,
    removeModelFromInstance,
    setComparableModels,
    setHelpOpen,
    setSearch,
    setHelpExpand,
    setDemoOn,
    setScenario,
} = appSlice.actions;

// selectors
export const selectEntities = (state: RootState): Entity[] => state.app.entities;
export const selectEntityModels = (state: RootState): any[] => state.app.entityModels;
export const selectObservations = (state: RootState): Observation[] =>
    _.map(state.app.observations, (observation) => {
        const activity = _.find(state.app.expectedActivities, { id: observation.activityId });
        const newObservation: Observation = {
            ...observation,
            activity: activity,
            activityId: activity ? activity.id : '',
        };
        return newObservation;
    });
export const selectExpectedActivities = (state: RootState): any[] => state.app.expectedActivities;
export const selectLocations = (state: RootState): any[] => state.app.locations;
export const selectInstances = (state: RootState): Instance[] => state.app.instances;
export const selectModelsWithInstances = (state: RootState): any[] => {
    const exportModels: ModelWithInstance[] = _.map(state.app.entityModels, (model: Model) => ({
        ...model,
        instances: _.filter(state.app.instances, (instance: Instance) => instance.model === model.id),
    }));

    // add unassigned model for holding all non-assigned instances
    if (exportModels.length > 0) {
        exportModels.push({
            id: -1,
            name: 'Unassigned',
            expectedActivities: [],
            version: '',
            priorVersion: '',
            instances: [],
            matching: { isMatching: false, observations: [] },
        });
    }

    return exportModels;
};
export const selectModelsGroupedByLabel = (state: RootState): any[] => getGroupedByLabel(state.app.entityModels);
export const selectEntitiesGroupedByLabel = (state: RootState): any[] => getGroupedByLabel(state.app.entities);
export const selectLocationsGroupedByLabel = (state: RootState): any[] => getGroupedByLabel(state.app.locations);
export const selectPlotFilterSelectionVisibleModelIds = (state: RootState): any => {
    let bModels = [];
    if (state.view.plotFilterSelectedEntities[0]) {
        // selects only models allowed by location, entity selections
        bModels = state.app.entityModels.filter((model: Model) =>
            state.view.plotFilterSelectedEntities.includes(model.id)
        );
    } else {
        bModels = [...state.app.entityModels];
    }

    if (state.view.plotFilterSelectedLocations[0]) {
        let locationModels: any = [];
        bModels.forEach((model) => {
            let flag = false;
            model.expectedActivities.forEach((EAID) => {
                const KLID = state.app.expectedActivities.find((activity) => activity.id === EAID.id);
                state.view.plotFilterSelectedLocations.forEach((ele: Activity) => {
                    if (ele.locationId === KLID?.locationId) {
                        flag = true;
                    } else {
                        flag = false;
                    }
                });
            });
            if (flag) {
                locationModels.push(model);
            } else {
                locationModels = [];
            }
        });

        return locationModels.map((model: any) => model.id);
    }
    return bModels.map((model) => model.id);
};
export const selectPlotFilterSelectionVisibleEntityIds = (state: RootState): any => {
    let entitiesFromLocations = getEntityIdsMatchingLocations(state.app.entities, state.app.locations);
    entitiesFromLocations = removeDuplicates(entitiesFromLocations);
    let entitiesFromModels: any[] = [];
    if (state.view.plotFilterSelectedModels.length > 0) {
        state.view.plotFilterSelectedModels.forEach((m: any) => {
            entitiesFromModels.push(
                state.app.entityModels.find((model: any) => {
                    let mod;
                    if (m === model.id) {
                        mod = model;
                    }
                    return mod;
                })
            );
        });
    } else {
        entitiesFromModels = [...state.app.entities];
    }

    const arr: any[] = [];
    entitiesFromModels.forEach((b) => {
        arr.push(b.id);
    });
    entitiesFromModels = arr;
    entitiesFromModels = removeDuplicates(entitiesFromModels);
    if (entitiesFromModels[0] && entitiesFromLocations) {
        return _.filter(entitiesFromLocations, (id: any) => _.includes(entitiesFromModels, id));
    }
    if (entitiesFromModels[0]) {
        return entitiesFromModels;
    }
    if (entitiesFromLocations[0]) {
        return entitiesFromLocations;
    }
    return state.app.entities.map((entity: any) => entity.id);
};
export const selectPlotFilterSelectionVisibleLocationIds = (state: RootState): any => {
    // filter to selected models and extract expectedActivities
    let modelEntityIds: any[] = [];
    const models = [...state.app.entityModels];
    models
        .filter((model) => state.view.plotFilterSelectedModels.includes(model.id))
        .forEach((model) => (modelEntityIds = modelEntityIds.concat(model.expectedActivities))); // eslint-disable-line no-return-assign
    modelEntityIds = removeDuplicates(modelEntityIds);
    // extract location ids from expected activities from models
    const modelLocations = state.app.expectedActivities
        .filter((activity) => modelEntityIds.includes(activity.id))
        .map((activity) => activity.locationId);
    // filter entities by selected and extract locations
    let kitLocIds: any[] = [];
    const tempEntities = state.app.entities.filter((entity) =>
        state.view.plotFilterSelectedEntities.includes(entity.id)
    );
    tempEntities.forEach((entity) => {
        kitLocIds = kitLocIds.concat(entity.locations);
    });
    kitLocIds = removeDuplicates(kitLocIds);
    // return visible locations
    if (modelLocations[0] && kitLocIds[0]) {
        return kitLocIds.filter((id) => modelLocations.includes(id));
    }
    if (modelLocations[0]) {
        return modelLocations;
    }
    if (kitLocIds[0]) {
        return kitLocIds;
    }
    const locs = [...state.app.locations];
    return locs.map((location) => location.id);
};
export const selectComparableModels = (state: RootState): any[] => state.app.comparableModels;
export const selectHelpOpen = (state: RootState): boolean => state.app.helpOpen;
export const selectSearch = (state: RootState): any => state.app.search;
export const selectHelpExpand = (state: RootState): any => state.app.helpExpand;
export const selectDemoOn = (state: RootState): any => state.app.demoOn;
export const selectScenario = (state: RootState): string => state.app.scenario;

export default appSlice.reducer;
