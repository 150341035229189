/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import { createServer, Model } from 'miragejs';
import _ from 'lodash';

import scenarioData from 'data/index.json';

const makeServer = ({ environment = 'test' } = {}): any => {
    const server = createServer({
        environment,
        models: {
            scenario: Model,
        },
        seeds(s) {
            _.forEach(scenarioData, (scenario) => {
                const entities = require(`./data/${scenario.name}/entities.json`);
                const expectedActivities = require(`./data/${scenario.name}/expectedActivities.json`);
                const instances = require(`./data/${scenario.name}/instances.json`);
                const observations = require(`./data/${scenario.name}/observations.json`);
                const entityModels = _.flatten(
                    _.map(entities, (e) =>
                        _.map(e.models, (m) => {
                            const activities = _.map(m.expectedActivities, (activity) => ({
                                ...activity,
                                observation: null,
                            }));
                            return {
                                ...m,
                                expectedActivities: activities,
                                matching: {
                                    isMatching: false,
                                    observations: [],
                                },
                            };
                        })
                    )
                );
                const locations = _.flatten(_.map(entities, 'locations'));
                const statuses = [
                    {
                        id: 0,
                        name: 'IN PROCESS',
                    },
                    {
                        id: 1,
                        name: 'COMPLETE',
                    },
                    {
                        id: 2,
                        name: 'ANOMALY',
                    },
                ];

                return s.create('scenario', {
                    ...scenario,
                    entities,
                    expectedActivities,
                    instances,
                    observations,
                    entityModels,
                    locations,
                    statuses,
                });
            });
        },
        routes() {
            this.namespace = 'api';
            this.get('/scenarios', (schema: any) => schema.scenarios.all());
            this.get('/scenarios/:name', (schema: any, request: any) =>
                schema.scenarios.findBy({ name: request.params.name })
            );
            this.passthrough((request) => _.startsWith(request.url, '/help'));
        },
    });

    return server;
};

export default makeServer;
