import React, { ReactElement } from 'react';
import { Checkbox, List, ListItem, ListItemText } from '@material-ui/core';
import _ from 'lodash';

import { addViewFilterType, removeViewFilterType, selectFilterTypes } from 'app/viewSlice';
import { Identifier } from 'types/Identifier';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import './CheckboxList.scss';

interface Props {
    filterItems: Identifier[];
}

const CheckboxList = ({ filterItems }: Props): ReactElement => {
    const dispatch = useAppDispatch();
    const appFilterTypes = useAppSelector(selectFilterTypes);
    const handleToggle = (value: number) => () => {
        const currentIndex = _.indexOf(appFilterTypes, value);
        if (currentIndex < 0) {
            dispatch(addViewFilterType(value));
        } else {
            dispatch(removeViewFilterType(value));
        }
    };
    return (
        <div className="CheckboxList">
            <List>
                {filterItems.map((value) => (
                    <ListItem key={value.id} role={undefined} dense button onClick={handleToggle(value.id)}>
                        <Checkbox checked={_.indexOf(appFilterTypes, value.id) !== -1} tabIndex={-1} disableRipple />
                        <ListItemText primary={value.name} />
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default CheckboxList;
