/* eslint-disable */

import React, { ReactElement } from 'react';
import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { usePromiseTracker } from 'react-promise-tracker';
import _ from 'lodash';

import { ModelWithInstance } from 'types/ModelWithInstance';
import { Observation } from 'types/Observation';
import { selectComparableModels, selectHelpOpen } from 'app/appSlice';
import { selectSelected, setViewSelected } from 'app/viewSlice';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import ModelsExpanded from 'components/DataOrganization/ModelsExpanded';
import ModelsList from 'components/DataOrganization/ModelsList';
import ReviewListTable from 'components/DataOrganization/ReviewListTable';

import './DataOrgWorkspace.scss';

const DataOrgWorkspace = (): ReactElement => {
    const dispatch = useAppDispatch();
    const appSelected = useAppSelector(selectSelected);
    const appComparableModels = useAppSelector(selectComparableModels);
    const appHelpOpen = useAppSelector(selectHelpOpen);
    const { promiseInProgress } = usePromiseTracker();

    const handleClick = (event: any, observation: Observation): void => {
        const selectedIndex = _.findIndex(appSelected, { activityId: observation.activityId });
        let newSelected: any = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(appSelected, observation);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(appSelected.slice(1));
        } else if (selectedIndex === appSelected.length - 1) {
            newSelected = newSelected.concat(appSelected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(appSelected.slice(0, selectedIndex), appSelected.slice(selectedIndex + 1));
        }
        dispatch(setViewSelected(newSelected));
    };

    const modelsExpandedEls = _.map(appComparableModels, (model: ModelWithInstance) => (
        <ModelsExpanded modelData={model} key={`model_${model.id}`} />
    ));

    return (
        <div className="DataOrgWorkspace">
            <Grid container spacing={3} className="DataOrgWorkspace" role="main">
                <Grid item xs={appHelpOpen ? 5 : 4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Paper>
                                <div className="DataOrgWorkspace__review-list">
                                    {promiseInProgress ? (
                                        <div className="DataOrgWorkspace__spinner">
                                            <CircularProgress color="secondary" size={40} />
                                        </div>
                                    ) : (
                                        <ReviewListTable
                                            handleClick={(event: any, id: any) => handleClick(event, id)}
                                        />
                                    )}
                                </div>
                            </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <div className="DataOrgWorkspace__models-list">
                                    {promiseInProgress ? (
                                        <div className="DataOrgWorkspace__spinner">
                                            <CircularProgress color="secondary" size={40} />
                                        </div>
                                    ) : (
                                        <ModelsList />
                                    )}
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={appHelpOpen ? 7 : 8}>
                    <Paper className="DataOrgWorkspace__models-expanded" id="expandedModel">
                        {!appComparableModels?.length && (
                            <div className="DataOrgWorkspace__models-expanded-placeholder">
                                <Typography variant="h2">
                                    Please toggle a model from the "Expand Models" section
                                </Typography>
                            </div>
                        )}
                        {appComparableModels?.length > 0 && (
                            <div className="DataOrgWorkspace__modelsList-content">{modelsExpandedEls}</div>
                        )}
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default DataOrgWorkspace;
