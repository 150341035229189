import React, { ReactElement, useEffect } from 'react';
import { FormControl, Input, InputAdornment, InputLabel, MenuItem, Select } from '@material-ui/core';
import { usePrevious } from 'react-use';
import _ from 'lodash';

import { MeanTimeControls } from 'types/MeanTimeControls';
import { selectEntityModels, selectEntities, selectExpectedActivities, selectScenario } from 'app/appSlice';
import { selectMeanTimeControls, setMeanTimeControls } from 'app/viewSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import './MeanTime.scss';

const MeanTime = (): ReactElement => {
    const dispatch = useAppDispatch();
    const appMeanTimeControls: MeanTimeControls = useAppSelector(selectMeanTimeControls);
    const appModels = useAppSelector(selectEntityModels);
    const appEntities = useAppSelector(selectEntities);
    const appExpectedActivities = useAppSelector(selectExpectedActivities);
    const appScenario = useAppSelector(selectScenario);

    const resetData = (): void => {
        dispatch(
            setMeanTimeControls({
                selectedModel: '-1',
                selectedSubEvent: '-1',
                subEvents: [],
                meanTimeToEvent: '',
            })
        );
    };

    const handleChange = (event: any) => {
        let selModel;
        switch (event.target.name) {
            case 'model':
                if (event.target.value === appMeanTimeControls.selectedModel) {
                    break;
                }
                if (event.target.value !== '-1') {
                    selModel = appModels.find((model) => model.id === event.target.value);
                    dispatch(
                        setMeanTimeControls({
                            selectedModel: event.target.value,
                            selectedSubEvent: '-1',
                            subEvents: selModel.expectedActivities,
                            meanTimeToEvent: '',
                        })
                    );
                } else {
                    resetData();
                }
                break;
            case 'subevent':
                if (event.target.value !== '-1') {
                    // lookup meantime
                    selModel = _.find(appModels, (model: any) => model.id === appMeanTimeControls.selectedModel);
                    const mTTE = _.find(selModel.expectedActivities, (e: any) => e.id === event.target.value);
                    dispatch(
                        setMeanTimeControls({
                            ...appMeanTimeControls,
                            selectedSubEvent: event.target.value,
                            meanTimeToEvent: mTTE.timeToEvent,
                        })
                    );
                } else {
                    // clear meantime
                    dispatch(
                        setMeanTimeControls({
                            ...appMeanTimeControls,
                            selectedSubEvent: event.target.value,
                            meanTimeToEvent: '',
                        })
                    );
                }
                break;
            default:
                break;
        }
    };

    _.forEach(appEntities, (el: any) => {
        const element = { ...el };
        if (element.name === 'Mile Two Desserts') {
            element.code = 'mt';
        } else {
            element.code = 'db';
        }
        _.forEach(element.models, (model: any) => {
            const m = { ...model };
            if (m.id.includes(element.code)) {
                m.locationId = element.id;
                m.entityId = element.id;
            }
        });
    });

    const prevScenario = usePrevious(appScenario);
    useEffect(() => {
        if (prevScenario && !_.isEqual(prevScenario, appScenario)) {
            resetData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appScenario]);

    return (
        <form className="MeanTimeViewer" id="meanTime">
            <div className="MeanTimeViewer__mtformControl">
                <FormControl className="MeanTimeViewer__mtformControl-select">
                    <InputLabel htmlFor="model">Model</InputLabel>
                    <Select
                        inputProps={{
                            name: 'model',
                            id: 'model',
                        }}
                        onChange={handleChange}
                        value={appMeanTimeControls.selectedModel}
                    >
                        <MenuItem value="-1">
                            <em>None</em>
                        </MenuItem>
                        {appModels.map((model) => (
                            <MenuItem key={model.id} value={model.id} id={model.id}>
                                {model.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    className="MeanTimeViewer__mtformControl-select"
                    disabled={appMeanTimeControls.selectedModel === '-1'}
                >
                    <InputLabel htmlFor="subevent">Sub Event</InputLabel>
                    <Select
                        inputProps={{
                            name: 'subevent',
                            id: 'subevent',
                        }}
                        onChange={handleChange}
                        value={appMeanTimeControls.selectedSubEvent}
                    >
                        <MenuItem value="-1">
                            <em>None</em>
                        </MenuItem>
                        {_.map(appMeanTimeControls.subEvents, (subEvent) => (
                            <MenuItem
                                key={`${subEvent.id}${_.indexOf(appExpectedActivities, subEvent)}`}
                                value={subEvent.id}
                            >
                                {subEvent.activityType}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <Input
                id="mean"
                placeholder="Mean Time to Event"
                value={appMeanTimeControls.meanTimeToEvent}
                disabled
                endAdornment={<InputAdornment position="end">Minutes</InputAdornment>}
                margin="none"
                className="MeanTimeViewer__mtformControl-input"
            />
        </form>
    );
};

export default MeanTime;
