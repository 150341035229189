/* eslint-disable */
import React, { ReactElement, useEffect, useState } from 'react';
import { usePrevious } from 'react-use';
import dayjs from 'dayjs';
import _ from 'lodash';

import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutline from '@material-ui/icons/RemoveCircleOutline';
import Badge from '@material-ui/core/Badge';
import Update from '@material-ui/icons/Update';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import Delete from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
    List,
    ListItem,
    ListItemText,
    FormGroup,
    IconButton,
    Tooltip,
    Popover,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Snackbar,
    Button,
} from '@material-ui/core';

import { Activity } from 'types/Activity';
import { Instance } from 'types/Instance';
import { Observation } from 'types/Observation';
import { selectSelected } from 'app/viewSlice';
import { addInstance, deleteInstance, selectInstances, selectObservations } from 'app/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';

import './ModelsExpanded.scss';

interface Props {
    modelData: any;
}

interface PopOverInfo {
    activity: Activity;
    instance: Instance;
    model: any;
    allObservations: Observation[];
    assignedObservation: Observation;
    matchingSelected: any[];
}

const ModelsExpanded = ({ modelData }: Props): ReactElement => {
    const dispatch = useAppDispatch();
    const appObservations = useAppSelector(selectObservations);
    const appInstances = useAppSelector(selectInstances);
    const appSelected = useAppSelector(selectSelected);
    const appliedValues: any[] = [];
    const selectedValues: any[] = [];
    const [expandedModel, setExpandedModel] = useState(modelData);
    const [showHide, setShowHide] = useState<boolean>(true);
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const [instanceAnchorEl, setInstanceAnchorEl] = useState<any>(null);
    const [instance, setInstance] = useState<Instance>();
    const [popOverInfo, setPopOverInfo] = useState<PopOverInfo>();
    const [predictiveBtnShow, setPredictiveBtnShow] = useState<boolean>(false);
    const [observationChoice, setObservationChoice] = useState<string>('');
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [snackMessage, setSnackMessage] = useState<string>('Observation Assigned!');

    const getTimestamp = (value: any) =>
        new Date(value).toLocaleDateString('en-US', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: 'numeric',
            hourCycle: 'h24',
            timeZone: 'America/New_York',
        });

    const setPercentValue = (
        entityId: string,
        instanceId: number,
        modelId: string | number,
        percent: any,
        dataSet: any
    ): void => {
        const current = _.find(dataSet, { entityId, instanceId, modelId });

        if (current) {
            current.percent = percent;
        } else {
            dataSet.push({
                entityId,
                instanceId,
                modelId,
                percent,
            });
        }
    };

    const getPercentValue = (eventId: any, instanceId: any, modelId: any, dataSet: any) => {
        const current = dataSet.find((x: any) => {
            return x.entityId === eventId && x.instanceId === instanceId && x.modelId === modelId;
        });
        if (current) return current.percent;
        return null;
    };

    const generatePercentChart = (percent: any, bar: any) => {
        if (percent === -2) percent = 0;
        let barStyle: any = { width: `${percent}%` };
        if (percent < 50) {
        } else if (percent > 50) {
        }
        // barStyle.width = Math.abs(barPercent) + '%';
        return (
            <div>
                <div className={`Bar ${bar}`} style={barStyle} />
            </div>
        );
    };

    const generateFillerBar = (percent: any, bar: any) => {
        if (percent === -2) percent = 0;
        return (
            <div>
                <div className={`Bar Filler ${bar}`} style={{ width: percent + '%' }} />
            </div>
        );
    };

    const getMostRecentSelected = (entities: any[], currentIndex: number): any =>
        entities.reduce(
            (value, a, i) => {
                let v = value;
                if (a.observation && i < currentIndex) {
                    v = { object: a, i };
                }
                return v;
            },
            { object: null, i: -1 }
        );

    const getMostRecentApplied = (
        entities: any[],
        instanceObservation: Observation[],
        currentIndex: number,
        priorIndex: number
    ): any =>
        entities.reduce(
            (value, a, i) => {
                let v = value;
                if ((priorIndex && i > priorIndex && i < currentIndex) || (!priorIndex && i < currentIndex)) {
                    const found = instanceObservation.findIndex((o: Observation) => o.activityId === a.id);
                    if (found > -1) {
                        v = { object: a, i };
                    }
                }
                return v;
            },
            { object: null, i: -1 }
        );

    const getNextSelected = (entities: any[], currentIndex: number): any =>
        entities.reduce(
            (value, a, i) => {
                let v = value;
                if (currentIndex !== -1 && i > currentIndex && a.observation) {
                    if (value.i === -1) {
                        v = { object: a, i };
                    }
                }
                return v;
            },
            { object: null, i: -1 }
        );

    const getNextApplied = (events: any, instObs: any, currentIndex: any) => {
        return events.reduce(
            (value: any, a: any, i: any) => {
                if (currentIndex !== -1 && i > currentIndex) {
                    var found = instObs.findIndex((o: any) => o.activityId === a.id);
                    if (value.i === -1 && found > -1) value = { object: a, i: i };
                }
                return value;
            },
            { object: null, i: -1 }
        );
    };

    const generatePercent = (priorObs: any, currentObs: any, priorEvent: any, event: any, variance: any) => {
        let obsDiff: any = dayjs(priorObs.sensorTimestamp).diff(dayjs(currentObs.sensorTimestamp), 'millisecond');
        obsDiff = obsDiff / 60000;
        const eventDiff = priorEvent.timeToEvent - event.timeToEvent;
        const diff = obsDiff - eventDiff;
        let percent = (diff * 50) / variance;
        if (percent < -50) percent = 97;
        else if (percent > 50) percent = 5;
        else if (percent < 0) percent = 50 + Math.abs(percent);
        else if (percent > 0) percent = 50 - percent;
        return percent;
    };

    const generateAppliedBar = (eventValue: any, instance: any) => {
        const events = expandedModel.expectedActivities;
        const instObs = instance.observations;
        if (instObs.length === 0) return null;
        let priorObsId: any, priorObs: any, priorEventIndex: any;
        const appliedPercent = getPercentValue(eventValue.id, instance.id, expandedModel.id, appliedValues);
        const currentObsIndex = instObs.findIndex((o: any) => {
            return o.activityId === eventValue.id;
        });
        const currentEventIndex = events.findIndex((a: any) => {
            return a.id === eventValue.id;
        });
        let currentObs = null;
        let laterPercent = null;
        // Get Observation Data
        if (!currentObs) {
            currentObs = instObs[currentObsIndex];
        }
        // find the most recent selected
        let potential = getMostRecentSelected(events, currentEventIndex);
        if (potential && potential.i !== -1) {
            priorObsId = potential.object.observation.id;
            priorObs = appObservations.find((o: any) => o.id === priorObsId);
            priorEventIndex = potential.i;
        }
        // find the most recent applied
        if (instance.observations) {
            potential = getMostRecentApplied(events, instObs, currentEventIndex, priorEventIndex);
            if (potential && potential.i !== -1) {
                priorObs = instObs.find((o: any) => o.activityId === potential.object.id);
                priorEventIndex = potential.i;
            }
        }
        if (currentObsIndex === -1 && currentEventIndex > 0 && priorObs) {
            if (currentEventIndex === events.length - 1) return null;
            // find the next one
            const nextEvent = getNextApplied(events, instObs, currentEventIndex);
            if (nextEvent.i !== -1) {
                laterPercent = getPercentValue(nextEvent.object.id, instance.id, expandedModel.id, appliedValues);
            }
            if (laterPercent) {
                return generateFillerBar(laterPercent, 'Applied');
            } else {
                return null;
            }
        }
        if (!priorObs && currentObs) {
            return <div className="varianceBars"></div>;
        } else if (!priorObs && !currentObs) {
            return null;
        }
        const priorEvent = events.find((e: any) => e.id === priorObs.activityId);
        let variance = 30;
        let percent = generatePercent(priorObs, currentObs, priorEvent, eventValue, variance);
        setPercentValue(eventValue.id, instance.id, expandedModel.id, percent, appliedValues);
        return generatePercentChart(percent, 'Applied');
    };

    const generateSelectedBar = (activity: any, instance: any) => {
        const events = expandedModel.expectedActivities;
        const instObs = instance.observations;
        let priorObsId: any, priorObs: any, priorEventIndex: any;

        const selectedPercent = getPercentValue(activity.id, instance.id, expandedModel.id, selectedValues);
        let laterPercent = null;
        const currentEventIndex = events.findIndex((a: any) => a.id === activity.id);
        // find the most recent selected
        let potential = getMostRecentSelected(events, currentEventIndex);
        if (potential && potential.i !== -1) {
            priorObsId = potential.object.observation.id;
            priorObs = appObservations.find((o: any) => o.id === priorObsId);
            priorEventIndex = potential.i;
        }
        // find the most recent applied
        if (instance.observations) {
            potential = getMostRecentApplied(events, instObs, currentEventIndex, priorEventIndex);
            if (potential && potential.i !== -1) {
                priorObs = instObs.find((o: any) => o.activityId === potential.object.id);
                priorEventIndex = potential.i;
            }
        }
        if (!activity.observation) {
            if (currentEventIndex === events.length - 1) return null;
            // find the next one
            const nextEvent = getNextSelected(events, currentEventIndex);
            const nextApplied = getNextApplied(events, instObs, currentEventIndex - 1);
            if (
                priorObs &&
                nextEvent.i !== -1 &&
                currentEventIndex > 0 &&
                (nextEvent.i <= nextApplied.i || nextApplied.i === -1)
            ) {
                generateSelectedBar(nextEvent.object, instance);
                laterPercent = getPercentValue(nextEvent.object.id, instance.id, expandedModel.id, selectedValues);
            }
            if (laterPercent) {
                return generateFillerBar(laterPercent, 'Selected');
            } else {
                return null;
            }
        } else if (activity.observation) {
            const currentObs = appObservations.find((o: any) => {
                return o.id === activity.observation.id;
            });
            if (!currentObs) return null; // FAIL - assigned, but doesn't exist
            if (!priorObs) {
                return <div className="varianceBars"></div>;
            }
            let variance = 30;
            const priorEvent = events.find((e: any) => e.id === priorObs.activityId);
            const percent = generatePercent(priorObs, currentObs, priorEvent, activity, variance);

            setPercentValue(activity.id, instance.id, expandedModel.id, percent, selectedValues);
            return generatePercentChart(percent, 'Selected');
        }
        return null;
    };

    const generateKarlsPlot = (activity: any, instance: any) => {
        const currentEventIndex = expandedModel.expectedActivities.findIndex((a: any) => {
            return a.id === activity.id;
        });
        if (currentEventIndex < 0) {
            return null;
        } else if (currentEventIndex === 0) {
            return (
                <div className="Chart">
                    {/* <div className="VarianceBars"></div> */}
                    {generateAppliedBar(activity, instance)}
                    {generateSelectedBar(activity, instance)}
                </div>
            );
        }
        return (
            <div className="Chart">
                <div className="VarianceBars">
                    <div className="CenterBar" />
                    {/* <div className="VarianceBox" /> */}
                </div>
                {generateAppliedBar(activity, instance)}
                {generateSelectedBar(activity, instance)}
            </div>
        );
    };

    const precalculatePercentages = (instance: Instance): void => {
        if (!instance || !instance.observations.length) {
            return;
        }

        _.forEach(instance.observations, (observation: Observation) => {
            const entityObject = _.find(expandedModel.expectedActivities, { id: observation.activityId });
            generateAppliedBar(entityObject, instance);
        });
    };

    const addInstanceData = (): void => {
        const instances: any = appInstances;
        const time: number = new Date().getTime();
        const date: string = dayjs(time).toString();
        let nextId = 0;
        if (instances.length > 0) {
            nextId = Math.max(...instances.map((o: any) => o.id)) + 1;
        }
        const instanceObj: Instance = {
            id: nextId,
            model: expandedModel.id,
            entityId: expandedModel.id,
            lastEdited: date,
            status: 1,
            observations: [],
        };
        const newInstances = [...expandedModel.instances, instanceObj];
        const newExpandedModel = { ...expandedModel, instances: newInstances };
        setExpandedModel(newExpandedModel);
        dispatch(addInstance(instanceObj));
    };

    const removeInstance = (instance: Instance): void => {
        dispatch(deleteInstance(instance));
    };

    const inInstance = (inst: Instance, act: any): any => {
        let assigned = false;
        let ob: Observation | string = '';
        _.forEach(inst.observations, (observation: Observation) => {
            if (act.id === observation.activityId) {
                assigned = true;
                ob = observation;
            }
        });
        return { assigned, ob };
    };

    const handlePopChoiceChange = (event: any): void => {
        const ob: Observation | undefined = _.find(appObservations, { id: +event.target.value });
        setObservationChoice(event.target.value);
        setPredictiveBtnShow(ob?.predictedActualTime !== '');
    };

    const removeAssignedObservation = (observationToRemove: Observation, instance: Instance) => {
        const newInstance = _.cloneDeep(instance);
        const index: number = _.findIndex(expandedModel.instances, { id: newInstance.id });
        newInstance.observations = _.filter(
            newInstance.observations,
            (observation: Observation) => observation.id !== observationToRemove.id
        );
        const newExpandedModel = _.cloneDeep(expandedModel);
        newExpandedModel.instances[index] = newInstance;
        setExpandedModel(newExpandedModel);
        setSnackOpen(true);
        setSnackMessage('Observation Unassigned!');
        setAnchorEl(null);
    };

    const assignObservationTo = (instance: Instance, allObservations: Observation[]) => {
        const newInstance = _.cloneDeep(instance);
        const observationToAdd = _.find(allObservations, { id: +observationChoice }) as Observation;
        const index: number = _.findIndex(expandedModel.instances, { id: newInstance.id });
        newInstance.observations.push(observationToAdd);
        const newExpandedModel = _.cloneDeep(expandedModel);
        newExpandedModel.instances[index] = newInstance;
        setExpandedModel(newExpandedModel);
        setSnackOpen(true);
        setSnackMessage('Observation Assigned!');
        setAnchorEl(null);
    };

    const addPredictiveValue = (instance: Instance, allObservations: Observation[]) => {
        const observationToAdd = _.find(allObservations, { id: +observationChoice }) as Observation;
        const index: number = _.indexOf(expandedModel.instances, instance);
        const newInstance = _.cloneDeep(instance);
        newInstance.observations.push(observationToAdd);
        const newExpandedModel = _.cloneDeep(expandedModel);
        newExpandedModel.instances[index] = newInstance;
        setExpandedModel(newExpandedModel);
        setSnackOpen(true);
        setSnackMessage('Predictive Value Added!');
        setAnchorEl(null);
    };

    const handleSnackClose = (event: any, reason: string) => {
        if (reason !== 'clickaway') {
            setSnackOpen(false);
        }
    };

    const assignedTimestamp = getTimestamp(popOverInfo?.assignedObservation.sensorTimestamp);

    const prevModelData = usePrevious(modelData);
    useEffect(() => {
        if (!_.isEqual(prevModelData, modelData)) {
            setExpandedModel(modelData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelData]);

    return (
        <div className="ModelsExpanded">
            <div className="ModelsExpanded__title">
                <Typography variant="subtitle1" component="h2">
                    {_.startCase(expandedModel.name)}
                </Typography>
                <Tooltip title={<h3 className="Tooltip-title">Add Instance</h3>} arrow>
                    <IconButton size="small" onClick={addInstanceData} aria-label="addInstance">
                        <AddIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title={<h3 className="Tooltip-title">Toggle Observation Instances</h3>} arrow>
                    <IconButton size="small" onClick={(): void => setShowHide(!showHide)}>
                        <Badge badgeContent={expandedModel.instances.length} color="error">
                            {showHide ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </Badge>
                    </IconButton>
                </Tooltip>
            </div>
            <div className={`ModelsExpanded__models${showHide ? '' : '-hide'}`}>
                <List className="ModelsExpanded__models-modelPres">
                    {expandedModel.expectedActivities.map((expectedActivity: any) => {
                        const matchedIds = _.map(expandedModel.matching.observations, (ob) => ob.activityId);
                        const matching = _.includes(matchedIds, expectedActivity.id) ? '-matched' : '';
                        return (
                            <ListItem
                                className={`ModelsExpanded__models-modelPres-activityList${matching}`}
                                key={`entity_${expectedActivity.id}`}
                            >
                                <ListItemText
                                    className="Model__activity"
                                    primary={_.startCase(expectedActivity.activityType)}
                                    secondary={`${expectedActivity.timeToEvent} Min`}
                                    title={_.startCase(expectedActivity.activityType)}
                                />
                            </ListItem>
                        );
                    })}
                </List>

                <div className="ModelsExpanded__instances">
                    {expandedModel.instances.map((i: Instance) => {
                        // const display = showHide ? '' : '-none';
                        let totalTime = 0;
                        precalculatePercentages(i);
                        return (
                            <List className="ModelsExpanded__instances-instPres" key={`instance_${i.id}`}>
                                <ListItem>
                                    <Typography
                                        component="div"
                                        className="ModelsExpanded__instances-instPres-mlistSubheader"
                                    >
                                        <div>{`Instance ${i.id}`}</div>
                                        <Tooltip title={<h3 className="Tooltip-title">Instance Details</h3>} arrow>
                                            <IconButton
                                                size="small"
                                                onClick={(event: any) => {
                                                    setInstance(i);
                                                    setInstanceAnchorEl(event.currentTarget);
                                                }}
                                                aria-label="Case Options"
                                            >
                                                <ArrowDropDownCircleIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                </ListItem>
                                {expandedModel.expectedActivities.map((act: any) => {
                                    const status = inInstance(i, act);
                                    const activity = { ...act };
                                    activity.assigned = status.assigned;
                                    activity.assignedOb = status.ob;
                                    let highlight = '';
                                    const matchedIds = _.map(
                                        expandedModel.matching.observations,
                                        (ob) => ob.activityId
                                    );
                                    if (_.includes(matchedIds, activity.id) ? '-matched' : '') {
                                        highlight = '-matched';
                                    }
                                    let expectedCircle = '-activityCircle';
                                    if (!activity.assigned) {
                                        expectedCircle = '-activityCircle NotAssigned';
                                        if (_.includes(matchedIds, activity.id)) {
                                            expectedCircle = '-activityCircle NotAssigned Matched';
                                        } else {
                                            expectedCircle = '-smallCircle';
                                        }
                                    } else {
                                        totalTime += act.timeToEvent;
                                        if (_.includes(matchedIds, activity.id) && activity.assignedOb) {
                                            expectedCircle = '-activityCircle Assigned Matched';
                                        } else {
                                            expectedCircle = '-activityCircle Assigned';
                                        }
                                        if (activity.assignedOb && activity.assignedOb.predictedActualTime !== '') {
                                            expectedCircle = '-activityCircle Assigned PredictedTime';
                                        }
                                    }

                                    return (
                                        <ListItem
                                            className={`ModelsExpanded__instances-instPres-mlistSubheader-predictIcon-activityList${highlight}`}
                                            key={`${i.id}_${activity.id}_${activity.activityType}`}
                                        >
                                            <FormGroup>
                                                <Tooltip
                                                    title={
                                                        <h3 className="Tooltip-title">{`Sensor Timestamp: ${status.ob.sensorTimestamp}`}</h3>
                                                    }
                                                    arrow
                                                >
                                                    <div
                                                        className={`ModelsExpanded__instances-instPres-mlistSubheader-predictIcon-activityList${expectedCircle}`}
                                                        onClick={(event: any) => {
                                                            const popover: PopOverInfo = {
                                                                activity,
                                                                instance: i,
                                                                model: expandedModel,
                                                                allObservations: appObservations,
                                                                assignedObservation: status.ob,
                                                                matchingSelected: [],
                                                            };
                                                            const tempObservations: any[] = _.map(
                                                                appSelected,
                                                                (observation: Observation) =>
                                                                    _.find(appObservations, { id: observation.id })
                                                            );
                                                            _.forEach(tempObservations, (o: Observation) => {
                                                                if (o.activityId === activity.id) {
                                                                    popover.matchingSelected.push(o);
                                                                }
                                                            });
                                                            setAnchorEl(event.currentTarget);
                                                            setPopOverInfo(popover);
                                                        }}
                                                        role="presentation"
                                                    />
                                                </Tooltip>
                                            </FormGroup>
                                            {generateKarlsPlot(activity, i)}
                                        </ListItem>
                                    );
                                })}
                                <ListItem>
                                    <div className="ModelsExpanded__totalTime">
                                        <Typography variant="body1">Total Time</Typography>
                                        <Typography variant="body1">{totalTime} Min</Typography>
                                    </div>
                                </ListItem>
                            </List>
                        );
                    })}
                </div>
            </div>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={(): void => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <aside>
                    <div className="ModelsExpanded__popOver">
                        <Typography variant="h6" component="h1" gutterBottom>
                            {_.startCase(popOverInfo?.activity.activityType)}
                        </Typography>
                        {popOverInfo?.assignedObservation.id && (
                            <Typography variant="body2" gutterBottom>
                                <strong>Current Assignment:</strong> <br />
                                {popOverInfo.assignedObservation.id} - {assignedTimestamp}
                            </Typography>
                        )}
                        {!popOverInfo?.assignedObservation.id && (
                            <Typography variant="body2" gutterBottom>
                                <strong>Current Assignment:</strong> <br />
                                NONE
                            </Typography>
                        )}
                        {!popOverInfo?.assignedObservation.id && (
                            <FormControl component="fieldset">
                                <FormLabel>
                                    <Typography variant="body2">
                                        <strong>Assign an Observation:</strong>
                                    </Typography>
                                </FormLabel>
                                <RadioGroup
                                    aria-label="ObservationChoice"
                                    name="ObservationChoice"
                                    // className={classes.group}
                                    value={observationChoice}
                                    onChange={handlePopChoiceChange}
                                >
                                    {popOverInfo?.matchingSelected.map((selected) => {
                                        const time = getTimestamp(selected.sensorTimestamp);
                                        return (
                                            <FormControlLabel
                                                key={selected.id}
                                                value={`${selected.id}`}
                                                control={<Radio />}
                                                label={`${selected.id} - ${time}`}
                                            />
                                        );
                                    })}
                                </RadioGroup>
                            </FormControl>
                        )}
                        <br />
                        <br />
                        {popOverInfo?.activity.observation && (
                            <div className="ModelsExpanded__popOver-popOverBtns">
                                {!popOverInfo.assignedObservation.id && !predictiveBtnShow && (
                                    <AddIcon
                                        onClick={() =>
                                            assignObservationTo(popOverInfo.instance, popOverInfo.allObservations)
                                        }
                                    />
                                )}

                                {popOverInfo.assignedObservation.id && (
                                    <RemoveCircleOutline
                                        onClick={() =>
                                            removeAssignedObservation(
                                                popOverInfo.assignedObservation,
                                                popOverInfo.instance
                                            )
                                        }
                                    />
                                )}

                                {predictiveBtnShow && (
                                    <Update
                                        onClick={() =>
                                            addPredictiveValue(popOverInfo.instance, popOverInfo.allObservations)
                                        }
                                    />
                                )}
                            </div>
                        )}
                    </div>
                </aside>
            </Popover>
            <Popover
                open={Boolean(instanceAnchorEl)}
                anchorEl={instanceAnchorEl}
                onClose={(): void => setInstanceAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                {instance && (
                    <div className="ModelsExpanded__instancePopover">
                        <div>
                            Source projected finish: {getTimestamp(instance.predictiveValue?.predictedActualTime)}
                        </div>
                        <Button
                            startIcon={<Delete />}
                            onClick={() => {
                                setInstanceAnchorEl(null);
                                removeInstance(instance);
                            }}
                        >
                            Delete Instance
                        </Button>
                    </div>
                )}
            </Popover>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={snackOpen}
                autoHideDuration={3500}
                onClose={handleSnackClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{snackMessage}</span>}
            />
        </div>
    );
};

export default ModelsExpanded;
