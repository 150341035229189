import React, { ReactElement } from 'react';
import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel } from '@material-ui/core';
import _ from 'lodash';

import { selectOrder, selectOrderBy, selectSelected } from 'app/viewSlice';
import { useAppSelector } from 'app/hooks';
import Info from 'components/Info/Info';

interface Props {
    onSelectAllClick: (event: any, checked: boolean) => void;
    onRequestSort: (event: any, orderBy: string) => void;
    rowCount: number;
}

interface Column {
    id: string;
    label: string;
    tooltip?: string;
}

const EnhancedTableHead = ({ onSelectAllClick, onRequestSort, rowCount }: Props): ReactElement => {
    const numSelected = useAppSelector(selectSelected).length;
    const appOrder = useAppSelector(selectOrder);
    const appOrderBy = useAppSelector(selectOrderBy);
    const columnData: Column[] = [
        { id: 'actName', label: 'Observation' },
        { id: 'sensorTimestamp', label: 'Date/Time' },
        { id: 'entityId', label: 'Entity', tooltip: 'An entity is the object that is being observed' },
        {
            id: 'predictedActualTime',
            label: 'Product',
            tooltip:
                'A clock indicates indirect intelligence (information gathered) while an image indicates direct intelligence',
        },
        {
            id: 'matching',
            label: 'Models',
            tooltip:
                'Related Models indicates the number of models that contain steps that match the observation description',
        },
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={(event: any, value: boolean) => onSelectAllClick(event, value)}
                        inputProps={{
                            'aria-label': 'Checkbox',
                        }}
                    />
                </TableCell>
                {_.map(
                    columnData,
                    (column: Column): ReactElement => (
                        <TableCell key={column.id} sortDirection={appOrderBy === column.id ? appOrder : undefined}>
                            <TableSortLabel
                                active={appOrderBy === column.id}
                                direction={appOrder}
                                onClick={(event: any): void => onRequestSort(event, column.id)}
                            >
                                {column.tooltip && (
                                    <div className="Flex-layout">
                                        <div>{column.label}</div>
                                        <Info title={column.tooltip} />
                                    </div>
                                )}
                                {!column.tooltip && column.label}
                            </TableSortLabel>
                        </TableCell>
                    )
                )}
            </TableRow>
        </TableHead>
    );
};

export default EnhancedTableHead;
