/* eslint-disable */
import React, { ReactElement, useState } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';

import ControlPanel from 'components/vis/ControlPanel';
import MeanTime from 'components/vis/MeanTime';
import PlotViewer from 'components/vis/PlotViewer';

import './Plotting.scss';
import { useAppSelector } from 'app/hooks';
import { selectPlotState } from 'app/viewSlice';

const Plotting = (): ReactElement => {
    const appPlotState = useAppSelector(selectPlotState);
    // const [currentData, setCurrentData] = useState<any[]>([]);

    return (
        <div className="Plotting">
            <main>
                <Grid container spacing={4}>
                    <Grid item xs={4}>
                        <Paper className="Plotting__meanTimeArea">
                            <h2>Mean Time to Event</h2>
                            <MeanTime />
                        </Paper>
                        <br />
                        <Paper className="Plotting__controlsArea">
                            <h2>Plotting Controls</h2>
                            <ControlPanel />
                        </Paper>
                    </Grid>
                    <Grid item xs={8}>
                        <Paper className="Plotting__plottingArea" id="plot">
                            {appPlotState[0]?.x[0] === undefined || appPlotState[0]?.y[0] === undefined ? (
                                <div className="Plotting__plottingArea-placeholder">
                                    <Typography variant="h3">
                                        Please Select an X Axis and Y Axis Value in the Plotting Controls Panel
                                    </Typography>
                                </div>
                            ) : (
                                <>
                                    <h2>Plotting Area</h2>
                                    <PlotViewer />
                                </>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </main>
        </div>
    );
};

export default Plotting;
