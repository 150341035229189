import React, { ReactElement, useState } from 'react';
import { TextField } from '@material-ui/core';
import { useDebounce, usePrevious } from 'react-use';
import _ from 'lodash';

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectSearch, setSearch } from 'app/appSlice';

const Search = (): ReactElement => {
    const appSearch = useAppSelector(selectSearch);
    const [searchQuery, setSearchQuery] = useState<string>(appSearch);
    const dispatch = useAppDispatch();

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const prevSearchQuery = usePrevious(searchQuery);
    useDebounce(
        () => {
            if (prevSearchQuery && !_.isEqual(prevSearchQuery, searchQuery)) {
                dispatch(setSearch(searchQuery));
            }
        },
        750,
        [prevSearchQuery, searchQuery]
    );

    return (
        <div className="Search">
            <TextField
                id="outlined-search"
                label="Search Help..."
                type="search"
                variant="outlined"
                color="secondary"
                size="small"
                fullWidth
                onChange={handleValueChange}
                inputProps={{ 'data-testid': 'search' }}
                value={searchQuery}
            />
        </div>
    );
};

export default Search;
