import { configureStore } from '@reduxjs/toolkit';
import appReducer from 'app/appSlice';
import viewReducer from 'app/viewSlice';

export const store = configureStore({
    reducer: {
        app: appReducer,
        view: viewReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
