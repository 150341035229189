/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactElement, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import { ArrowBackIos, Close, Fullscreen, FullscreenExit } from '@material-ui/icons';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import qs from 'query-string';
import _ from 'lodash';

import { selectDemoOn, selectHelpExpand, selectSearch, setDemoOn, setHelpExpand, setHelpOpen } from 'app/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import Search from './Search';

import './Help.scss';

interface Category {
    name: string;
    shortTitle: string;
    title: string;
    results: number;
}

const Help = (): ReactElement => {
    const dispatch = useAppDispatch();
    const appDemo = useAppSelector(selectDemoOn);
    const history = useHistory();
    const location = useLocation();
    const queryParams = qs.parse(location.search);
    const appSearch = useAppSelector(selectSearch);
    const appHelpExpand = useAppSelector(selectHelpExpand);
    const categories: Category[] = [
        {
            name: 'storyBehindTool',
            shortTitle: 'Story Behind the Tool',
            title: 'Story Behind the Tool',
            results: 0,
        },
        {
            name: 'howTo',
            shortTitle: 'How the Tool Works',
            title: 'How the Tool Works',
            results: 0,
        },
        {
            name: 'toolScenarios',
            shortTitle: 'Tool Scenarios',
            title: 'Tool Scenarios',
            results: 0,
        },
        {
            name: 'soWhat',
            shortTitle: 'So What...',
            title: 'So What...',
            results: 0,
        },
        {
            name: 'm2Story',
            shortTitle: 'The Mile Two Story',
            title: 'The Mile Two Story',
            results: 0,
        },
        {
            name: 'articles',
            shortTitle: 'Concepts & Literature',
            title: 'Concepts & Literature',
            results: 0,
        },
    ];
    const [headerText, setHeaderText] = useState<string>('Help Documentation');
    const [sectionActive, setSectionActive] = useState<string>('menu');
    const [helpText, setHelpText] = useState<any>(null);
    const [searchResults, setSearchResults] = useState<Category[]>(categories);

    const getPage = async (topic: string) => {
        const response = await fetch(`${process.env.PUBLIC_URL}/help/${topic}.md`);
        return response.text();
    };

    const pageContent = async (topic: string): Promise<void> => {
        const content = await getPage(topic);
        setHelpText(content);
    };

    const generateContent = (): ReactElement => (
        <div className="Help__body">
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>{helpText}</ReactMarkdown>
        </div>
    );

    const scanPages = async () => {
        const promises = _.map(categories, (category) => getPage(category.name));
        const content = await Promise.all(promises);
        const results = _.map(content, (text, idx) => ({
            name: categories[idx].name,
            title: categories[idx].title,
            shortTitle: categories[idx].shortTitle,
            results: _.size(_.filter(_.words(text), (word) => _.includes(word, appSearch?.toLowerCase()))),
        }));
        setSearchResults(results);
    };

    const resultEls: ReactElement[] = _.map(searchResults, (result, idx) => (
        <ListItem
            key={idx}
            button
            onClick={() => {
                setSectionActive(result.name);
                setHeaderText(result.shortTitle);
            }}
        >
            <ListItemText primary={result.title} secondary={appSearch ? `Mentions: ${result.results}` : ''} />
        </ListItem>
    ));

    useEffect(() => {
        if (sectionActive !== 'menu') {
            pageContent(sectionActive);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionActive]);

    useEffect(() => {
        if (appSearch !== '' && appSearch !== null && appSearch !== undefined) {
            scanPages();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appSearch]);

    useEffect(() => {
        if (queryParams.topic) {
            setSectionActive(queryParams.topic as string);
        }
    }, [queryParams]);

    return (
        <div className="Help">
            <div className="Help__header">
                {sectionActive !== 'menu' && (
                    <IconButton
                        className="Help__header-back"
                        size="small"
                        onClick={() => {
                            setSectionActive('menu');
                            setHeaderText('Help Documentation');
                            if (typeof queryParams.topic !== 'undefined') {
                                delete queryParams.topic;
                                history.push({
                                    pathname: location.pathname,
                                    search: qs.stringify(queryParams),
                                });
                            }
                        }}
                        aria-label="Help Menu"
                    >
                        <ArrowBackIos />
                    </IconButton>
                )}
                <div className="Flex-layout">
                    <h2 className="Help__header-text">{headerText}</h2>
                </div>
                <IconButton
                    size="small"
                    onClick={() => {
                        if (typeof queryParams.topic !== 'undefined') {
                            delete queryParams.topic;
                            history.push({
                                pathname: location.pathname,
                                search: qs.stringify(queryParams),
                            });
                        }
                        if (appDemo) {
                            dispatch(setDemoOn(false));
                        }
                        dispatch(setHelpOpen(false));
                    }}
                    aria-label="Close Help"
                >
                    <Close />
                </IconButton>
            </div>
            {sectionActive === 'menu' && (
                <List className="Help__body">
                    <ListItem className="Help__search" component="ul">
                        <Search />
                    </ListItem>
                    {resultEls}
                </List>
            )}
            {sectionActive !== 'menu' && generateContent()}
            <div className="Help__footer">
                <IconButton
                    className="Help__header-expand"
                    onClick={() => {
                        dispatch(setHelpExpand(!appHelpExpand));
                    }}
                    aria-label="toggle expansion"
                >
                    {appHelpExpand ? <FullscreenExit /> : <Fullscreen />}
                </IconButton>
            </div>
        </div>
    );
};

export default Help;
