/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactElement, useState } from 'react';
import Spotlight from 'react-spotlight';
import { useLocation } from 'react-use';

import { setDemoOn } from 'app/appSlice';
import { useAppDispatch } from 'app/hooks';

const Demo = (): ReactElement => {
    const dispatch = useAppDispatch();
    const [index, setIndex] = useState<number>(0);
    const location = useLocation();
    const page = location.pathname === '/' ? 0 : 1;

    const getOffset = (name: string) => {
        const el: Element | null = document.getElementById(name);
        const rect = el?.getBoundingClientRect();
        return {
            left: rect ? rect.left + window.scrollX : 0,
            top: rect ? rect.top + window.scrollY : 0,
            right: rect ? rect.right + window.scrollX : 0,
        };
    };

    const x: any[] = [
        { 0: getOffset('pageSelector').left + 170, 1: getOffset('pageSelector').left + 170 },
        { 0: getOffset('checkbox-0').left + 20, 1: getOffset('meanTime').left + 230 },
        { 0: getOffset('toggle-mt001').left + 25, 1: getOffset('controlPanel').left + 230 },
        { 0: getOffset('expandedModel').left + 220, 1: getOffset('plot').left + 400 },
        { 0: getOffset('helpButton').right - 45, 1: getOffset('helpButton').right - 45 },
    ];
    const y: any[] = [
        { 0: getOffset('pageSelector').top + 20, 1: getOffset('pageSelector').top + 20 },
        { 0: getOffset('checkbox-0').top + 25, 1: getOffset('meanTime').top + 65 },
        { 0: getOffset('toggle-mt001').top + 25, 1: getOffset('controlPanel').top + 115 },
        { 0: getOffset('expandedModel').top + 190, 1: getOffset('plot').top + 300 },
        { 0: getOffset('helpButton').top + 20, 1: getOffset('helpButton').top + 20 },
    ];
    const radius: any[] = [
        { 0: 170, 1: 170 },
        { 0: 40, 1: 220 },
        { 0: 70, 1: 220 },
        { 0: 200, 1: 350 },
        { 0: 50, 1: 50 },
    ];
    const messages: any[] = [
        {
            0: {
                title: 'Page Viewer',
                message: 'Navigate between this page and the Data Analysis page to explore more features',
            },
            1: {
                title: 'Page Viewer',
                message: 'Navigate between this page and the Data Org Workspace page to explore more features',
            },
        },
        {
            0: {
                title: 'Checkbox',
                message:
                    'Check an observation to highlight it on the Expanded Model graph and see how it relates to a selected model',
            },
            1: {
                title: 'Mean Time to Event',
                message: 'Calculate the average time a sub event takes in a selected model',
            },
        },
        {
            0: {
                title: 'Toggle Expanded Model',
                message:
                    'Toggle this switch to see instances related to that model. Expanded models will appear to the left and any selected observations will be highlighted',
            },
            1: {
                title: 'Plotting Controls',
                message:
                    'Controls how the data is visually displayed; the X Axis, Y Axis and filtering options can be specified to customize the visualization of the data',
            },
        },
        {
            0: {
                title: 'Model Expanded Chart',
                message:
                    'Models are displayed here in a chart that shows various instances compare to a given model. From here you can add instances or click events for more information. Filled in circles represent reported events and the distance from the line to the circle represent how closely the timelines matched up per instance.',
            },
            1: {
                title: 'Plotting Area',
                message: 'Displays a chart generated by options selected in the Plotting Controls area',
            },
        },
        {
            0: {
                title: 'Help Section',
                message:
                    'Click here for more information on the tool including insight into the development process, articles on how the tool works, and more on Mile Two!',
            },
            1: {
                title: 'Help Section',
                message:
                    'Click here for more information on the tool including insight into the development process, articles on how the tool works, and more on Mile Two!',
            },
        },
    ];

    const closeDemo = () => {
        setIndex(0);
        dispatch(setDemoOn(false));
    };
    const helpClass = index === 4 ? '__help' : '__text';
    return (
        <div role="presentation" onClick={() => (index !== 4 ? setIndex(index + 1) : closeDemo())}>
            <Spotlight
                x={x[index][page]}
                y={y[index][page]}
                color="#8bc34a"
                radius={radius[index][page]}
                responsive
                animSpeed={1000}
                borderColor="#ddd"
                borderWidth={5}
                outerClass="Spotlight"
                innerClass="Spotlight"
            >
                <div className={`Spotlight${helpClass}`}>
                    <div className={`Spotlight${helpClass}-title`}>{messages[index][page].title}</div>
                    <div className={`Spotlight${helpClass}-message`}>{messages[index][page].message}</div>
                    <div className={`Spotlight${helpClass}-continue`}>(Click to Continue...) {index + 1}/5</div>
                </div>
            </Spotlight>
        </div>
    );
};
export default Demo;
