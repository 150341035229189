import React, { ReactElement, useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TablePagination, TableRow, Checkbox } from '@material-ui/core';
import { Photo, Schedule } from '@material-ui/icons';
import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { usePrevious } from 'react-use';
import _ from 'lodash';

import { selectEntities, selectObservations, addObservations, selectModelsWithInstances } from 'app/appSlice';
import { Observation } from 'types/Observation';
import { Option } from 'types/Option';
import { RootState } from 'app/store';
import {
    selectStartDate,
    selectEndDate,
    setViewStartDate,
    setViewEndDate,
    setViewOrder,
    setViewOrderBy,
    setViewSelected,
    setViewPage,
    setViewRowsPerPage,
    selectRowsPerPage,
    selectPage,
    selectSelected,
    selectFilterTypes,
    selectOrder,
    selectOrderBy,
} from 'app/viewSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import EnhancedTableHead from 'components/DataOrganization/EnhancedTableHead';
import EnhancedTableToolbar from 'components/DataOrganization/EnhancedTableToolbar';

import './ReviewListTable.scss';

interface Props {
    handleClick: (event: any, value: any) => void;
}

const ReviewListTable = ({ handleClick }: Props): ReactElement => {
    dayjs.extend(isSameOrBefore);
    dayjs.extend(isSameOrAfter);
    const dispatch = useAppDispatch();
    const appView = useAppSelector((state: RootState) => state.view);
    const appObservations = useAppSelector(selectObservations);
    const appPage = useAppSelector(selectPage);
    const appRowsPerPage = useAppSelector(selectRowsPerPage);
    const appSelected = useAppSelector(selectSelected);
    const appEntities = useAppSelector(selectEntities);
    const appFilterTypes = useAppSelector(selectFilterTypes);
    const appStartDate = useAppSelector(selectStartDate);
    const appEndDate = useAppSelector(selectEndDate);
    const sortBy = useAppSelector(selectOrderBy);
    const sort = useAppSelector(selectOrder);
    const [filteredObservations, setFilteredObservations] = useState<Observation[]>([]);
    const appModelsWithInstances = useAppSelector(selectModelsWithInstances);

    const isSelected = (observation: Observation) =>
        _.findIndex(appSelected, { activityId: observation.activityId }) !== -1;

    const handleSelectAllClick = (event: any, checked: boolean): void => {
        if (checked) {
            dispatch(setViewSelected(_.map(filteredObservations, (n: Observation) => n)));
        } else {
            dispatch(setViewSelected([]));
        }
    };

    const handleChangePage = (event: any, page: number): void => {
        dispatch(setViewPage(page));
    };

    const handleChangeRowsPerPage = (event: any): void => {
        dispatch(setViewRowsPerPage(event.target.value));
    };

    const handleRequestSort = (event: any, orderBy: string): void => {
        const order = appView.orderBy === orderBy && appView.order === 'desc' ? 'asc' : 'desc';
        dispatch(setViewOrder(order));
        dispatch(setViewOrderBy(orderBy));
    };

    const handleTimeFilter = (event: any): void => {
        const value = dayjs(event.target.value);
        if (event.target.name === 'startFilter') {
            if (value.isSameOrBefore(dayjs(appEndDate))) {
                const observations = _.filter(
                    appObservations,
                    (observation: Observation) =>
                        dayjs(observation.sensorTimestamp).isSameOrAfter(value) &&
                        dayjs(observation.sensorTimestamp).isSameOrBefore(dayjs(appEndDate))
                );
                setFilteredObservations(observations);
                dispatch(setViewStartDate(value.format('YYYY-MM-DD[T]HH:mm:ss')));
            } else {
                /* eslint-disable-next-line no-alert */
                alert('You can not have Start time be after End time.');
                /* eslint-disable-next-line no-param-reassign */
                event.target.value = dayjs(appStartDate).format('YYYY-MM-DD[T]HH:mm:ss');
            }
        } else {
            /* eslint-disable-next-line no-lonely-if */
            if (value.isSameOrAfter(dayjs(appStartDate))) {
                const observations = _.filter(
                    filteredObservations,
                    (observation: Observation) =>
                        dayjs(observation.sensorTimestamp).isSameOrAfter(dayjs(appStartDate)) &&
                        dayjs(observation.sensorTimestamp).isSameOrBefore(value)
                );
                setFilteredObservations(observations);
                dispatch(setViewEndDate(value.format('YYYY-MM-DD[T]HH:mm:ss')));
            } else {
                /* eslint-disable-next-line no-alert */
                alert('You can not have Start time be after End time.');
                /* eslint-disable-next-line no-param-reassign */
                event.target.value = dayjs(appEndDate).format('YYYY-MM-DD[T]HH:mm:ss');
            }
        }
    };

    const uploadObservations = (event: any): void => {
        if (event.target.files && event.target.files.length) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (loadEvent: any): void => {
                dispatch(addObservations(JSON.parse(loadEvent.target.result)));
            };
            reader.readAsText(file);
        }
    };

    const tableRowEls: ReactElement[] = _.map(
        _.slice(filteredObservations, appPage * appRowsPerPage, appPage * appRowsPerPage + appRowsPerPage),
        (observation: Observation, idx: number) => {
            const newObservation: Observation = { ...observation };
            // Counting models related to observations
            const modObMatch = _.size(
                _.filter(appModelsWithInstances, (model: any) =>
                    _.find(model.expectedActivities, { id: newObservation.activityId })
                )
            );

            const selected = isSelected(newObservation);
            const entityIndex: number = _.findIndex(appEntities, {
                id: newObservation.entityId,
            });

            const options: Option = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: 'numeric',
                minute: 'numeric',
                hourCycle: 'h24',
                timeZone: 'America/New_York',
            };

            const time = new Date(newObservation.sensorTimestamp).toLocaleDateString('en-US', options);

            return (
                <TableRow
                    hover
                    onClick={(event: any) => handleClick(event, newObservation)}
                    role="row"
                    tabIndex={-1}
                    key={newObservation.id}
                    selected={selected}
                >
                    <TableCell padding="checkbox">
                        <Checkbox
                            checked={selected}
                            inputProps={{
                                'aria-label': 'Checkbox',
                            }}
                            id={`checkbox-${idx}`}
                        />
                    </TableCell>
                    <TableCell>{newObservation.activity?.activityType}</TableCell>
                    <TableCell>{time}</TableCell>
                    <TableCell>{entityIndex > -1 && appEntities[entityIndex].name}</TableCell>
                    <TableCell className="ReviewListTable__match">
                        {newObservation.predictedActualTime ? <Schedule /> : <Photo />}
                    </TableCell>
                    <TableCell className="ReviewListTable__match">{modObMatch}</TableCell>
                </TableRow>
            );
        }
    );

    useEffect(() => {
        if (appFilterTypes.length > 0) {
            const arr = _.flatten(
                _.map(appFilterTypes, (element: any) => _.filter(appObservations, { entityId: element }))
            );
            setFilteredObservations(arr);
        } else {
            setFilteredObservations(appObservations);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appFilterTypes]);

    useEffect(() => {
        setFilteredObservations(_.orderBy(filteredObservations, sortBy, sort));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, sortBy]);

    const prevAppObservations = usePrevious(appObservations);
    useEffect(() => {
        if (!_.isEqual(prevAppObservations, appObservations)) {
            setFilteredObservations(appObservations);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appObservations]);

    return (
        <div className="ReviewListTable">
            <div>
                <EnhancedTableToolbar handleTimeFilter={handleTimeFilter} uploadObservations={uploadObservations} />
                <div className="TableWrapper">
                    <Table aria-label="tableTitle">
                        <EnhancedTableHead
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={filteredObservations.length}
                        />
                        <TableBody className="TableBody">{tableRowEls}</TableBody>
                    </Table>
                </div>
            </div>
            <TablePagination
                component="div"
                count={filteredObservations.length}
                rowsPerPage={appRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                page={appPage}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    );
};
export default ReviewListTable;
