import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';

import { Entity } from 'types/Entity';
import { PlotControls, PlotState } from 'types/Plot';
import { RootState } from 'app/store';
import { MeanTimeControls } from 'types/MeanTimeControls';

interface SliceState {
    dirty: boolean;
    visibilityFilter: string;
    order: 'asc' | 'desc' | undefined;
    orderBy: string;
    entities: Entity[];
    filterTypes: any[];
    selected: any[];
    emSelected: any[];
    instanceSelected: number;
    filteredModels: any[];
    assignedModel: number;
    entityModelPageSelect: any;
    unassignedPageSelect: any;
    page: number;
    rowsPerPage: number;
    plotFilterSelectedModels: any[];
    plotFilterSelectedEntities: any[];
    plotFilterSelectedLocations: any[];
    startDate: string;
    endDate: string;
    selectedIndex: number;
    expandedModelIds: string[];
    plotState: PlotState[];
    plotControls: PlotControls;
    meanTimeControls: MeanTimeControls;
}

const initialState: SliceState = {
    dirty: false,
    visibilityFilter: 'SHOW_ALL',
    order: 'desc',
    orderBy: 'id',
    entities: [],
    filterTypes: [],
    selected: [],
    emSelected: [],
    instanceSelected: -1,
    filteredModels: [],
    assignedModel: -1,
    entityModelPageSelect: null,
    unassignedPageSelect: null,
    page: 0,
    rowsPerPage: 10,
    plotFilterSelectedModels: [],
    plotFilterSelectedEntities: [],
    plotFilterSelectedLocations: [],
    startDate: '',
    endDate: '',
    selectedIndex: -1,
    expandedModelIds: [],
    plotState: [],
    plotControls: {
        xaxis: '',
        yaxis: '',
        type: 'box',
        groupBy: '',
        tabSelect: 0,
        modelFilters: [],
        entityFilters: [],
        locationFilters: [],
        startTime: '',
        endTime: '',
    },
    meanTimeControls: {
        selectedModel: '-1',
        selectedSubEvent: '-1',
        subEvents: [],
        meanTimeToEvent: '',
    },
};

const removeItemFromArray = (array: any, item: any) => _.filter(array, (index: any) => index !== item);

export const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        setDirtyFlag: (state, action: PayloadAction<boolean>) => ({ ...state, dirty: action.payload }),
        setVisibilityFilter: (state, action: PayloadAction<string>) => ({ ...state, visibilityFilter: action.payload }),
        setViewStartDate: (state, action: PayloadAction<string>) => ({ ...state, startDate: action.payload }),
        setViewEndDate: (state, action: PayloadAction<string>) => ({ ...state, endDate: action.payload }),
        setViewOrder: (state, action: PayloadAction<'asc' | 'desc' | undefined>) => ({
            ...state,
            order: action.payload,
        }),
        setViewOrderBy: (state, action: PayloadAction<string>) => ({ ...state, orderBy: action.payload }),
        setViewSelected: (state, action: PayloadAction<any[]>) => ({ ...state, selected: action.payload }),
        setViewSelectedIndex: (state, action: PayloadAction<number>) => ({ ...state, selectedIndex: action.payload }),
        addViewSelected: (state, action: PayloadAction<any>) => ({
            ...state,
            selected: [...state.selected, action.payload],
        }),
        removeViewSelected: (state, action: PayloadAction<any>) => ({
            ...state,
            selected: removeItemFromArray(state.selected, action.payload),
        }),
        setViewFilterTypes: (state, action: PayloadAction<any[]>) => ({ ...state, filterTypes: action.payload }),
        addViewFilterType: (state, action: PayloadAction<any>) => ({
            ...state,
            filterTypes: [...state.filterTypes, action.payload],
        }),
        removeViewFilterType: (state, action: PayloadAction<any>) => ({
            ...state,
            filterTypes: removeItemFromArray(state.filterTypes, action.payload),
        }),
        setViewEntities: (state, action: PayloadAction<Entity[]>) => ({ ...state, entities: action.payload }),
        addViewEntity: (state, action: PayloadAction<Entity>) => ({
            ...state,
            entities: [...state.entities, action.payload],
        }),
        removeViewEntity: (state, action: PayloadAction<Entity>) => ({
            ...state,
            selected: removeItemFromArray(state.entities, action.payload),
        }),
        setViewPage: (state, action: PayloadAction<number>) => ({ ...state, page: action.payload }),
        setViewRowsPerPage: (state, action: PayloadAction<number>) => ({ ...state, rowsPerPage: action.payload }),
        setEmbankViewSelected: (state, action: PayloadAction<any[]>) => ({ ...state, emSelected: action.payload }),
        setEmAssignSelected: (state, action: PayloadAction<number>) => ({ ...state, assignedModel: action.payload }),
        setEmInstanceViewSelected: (state, action: PayloadAction<number>) => ({
            ...state,
            instanceSelected: action.payload,
        }),
        deleteInstance: (state, action: PayloadAction<number>) => {
            if (action.payload === state.instanceSelected) {
                return { ...state, instanceSelected: -1, dirty: true };
            }
            return state;
        },
        removeObservationFromInstance: (state, action: PayloadAction<any>) => ({
            ...state,
            dirty: true,
            selected: [...state.selected, action.payload],
        }),
        setPlotFilterSelectedEntities: (state, action: PayloadAction<any[]>) => ({
            ...state,
            plotFilterSelectedEntities: action.payload,
        }),
        setPlotFilterSelectedModels: (state, action: PayloadAction<any[]>) => ({
            ...state,
            plotFilterSelectedModels: action.payload,
        }),
        setPlotFilterSelectedLocations: (state, action: PayloadAction<any[]>) => ({
            ...state,
            plotFilterSelectedLocations: action.payload,
        }),
        addInstance: (state) => ({ ...state, dirty: true }),
        setInstanceEntity: (state) => ({ ...state, dirty: true }),
        linkObservationToInstance: (state) => ({ ...state, dirty: true }),
        linkInstanceToModel: (state) => ({ ...state, dirty: true }),
        removeModelFromInstance: (state) => ({ ...state, dirty: true }),
        setExpandedModelIds: (state, action: PayloadAction<string[]>) => ({
            ...state,
            expandedModelIds: action.payload,
        }),
        setPlotState: (state, action: PayloadAction<PlotState[]>) => ({
            ...state,
            plotState: action.payload,
        }),
        setPlotControls: (state, action: PayloadAction<PlotControls>) => ({
            ...state,
            plotControls: action.payload,
        }),
        setMeanTimeControls: (state, action: PayloadAction<MeanTimeControls>) => ({
            ...state,
            meanTimeControls: action.payload,
        }),
    },
});

// actions
export const {
    setDirtyFlag,
    setVisibilityFilter,
    setViewStartDate,
    setViewEndDate,
    setViewOrder,
    setViewOrderBy,
    setViewSelected,
    setViewSelectedIndex,
    addViewSelected,
    removeViewSelected,
    setViewFilterTypes,
    addViewFilterType,
    removeViewFilterType,
    setViewEntities,
    addViewEntity,
    removeViewEntity,
    setViewPage,
    setViewRowsPerPage,
    setEmbankViewSelected,
    setEmAssignSelected,
    setEmInstanceViewSelected,
    deleteInstance,
    removeObservationFromInstance,
    setPlotFilterSelectedEntities,
    setPlotFilterSelectedModels,
    setPlotFilterSelectedLocations,
    addInstance,
    setInstanceEntity,
    linkObservationToInstance,
    linkInstanceToModel,
    removeModelFromInstance,
    setExpandedModelIds,
    setPlotState,
    setPlotControls,
    setMeanTimeControls,
} = viewSlice.actions;

// selectors
export const selectSelected = (state: RootState): any[] => state.view.selected;
export const selectStartDate = (state: RootState): string => state.view.startDate;
export const selectEndDate = (state: RootState): string => state.view.endDate;
export const selectPage = (state: RootState): number => state.view.page;
export const selectRowsPerPage = (state: RootState): number => state.view.rowsPerPage;
export const selectOrder = (state: RootState): 'asc' | 'desc' | undefined => state.view.order;
export const selectOrderBy = (state: RootState): string => state.view.orderBy;
export const selectFilterTypes = (state: RootState): any[] => state.view.filterTypes;
export const selectPlotFilterSelectedModels = (state: RootState): any[] => state.view.plotFilterSelectedModels;
export const selectPlotFilterSelectedLocations = (state: RootState): any[] => state.view.plotFilterSelectedLocations;
export const selectPlotFilterSelectedEntities = (state: RootState): any[] => state.view.plotFilterSelectedEntities;
export const selectExpandedModelIds = (state: RootState): any => state.view.expandedModelIds;
export const selectPlotState = (state: RootState): any => state.view.plotState;
export const selectPlotControls = (state: RootState): any => state.view.plotControls;
export const selectMeanTimeControls = (state: RootState): any => state.view.meanTimeControls;

export default viewSlice.reducer;
