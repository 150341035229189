import React, { ReactElement } from 'react';
import Plot from 'react-plotly.js';

import { selectPlotState } from 'app/viewSlice';
import { useAppSelector } from 'app/hooks';

import './Plotting.scss';

const PlotViewer = (): ReactElement => {
    const appPlotState = useAppSelector(selectPlotState);
    return (
        <div>
            <Plot
                data={appPlotState}
                layout={{
                    boxmode: 'group',
                    width: 900,
                    height: 600,
                }}
            />
        </div>
    );
};

export default PlotViewer;
