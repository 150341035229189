import React, { ReactElement, useRef, useState } from 'react';
import { IconButton, Portal, Tooltip, Typography } from '@material-ui/core';
import { Close, FilterList, CloudUpload } from '@material-ui/icons';
import _ from 'lodash';

import { Entity } from 'types/Entity';
import { selectEntities } from 'app/appSlice';
import { useAppSelector } from 'app/hooks';
import FilterDropDown from 'components/FilterDropDown/FilterDropDown';
import Info from 'components/Info/Info';

import './ReviewListTable.scss';

interface Props {
    handleTimeFilter: any;
    uploadObservations: any;
}

const EnhancedTableToolbar = ({ handleTimeFilter, uploadObservations }: Props): ReactElement => {
    const container: any = useRef();
    const fileUpload: any = useRef();
    const [show, setShow] = useState<boolean>(false);
    const appEntities = useAppSelector(selectEntities);
    const filterItems = _.map(appEntities, (e: Entity) => ({ id: e.id, name: e.name }));

    return (
        <aside>
            <header className="ReviewToolbar">
                <div className="Flex-layout">
                    <Typography variant="subtitle1" component="h2" className="ReviewToolbar__Title">
                        Observations
                    </Typography>
                    <Info title="Observations are model events that have already taken place. Select observations to identify in which model(s) it is contained. When a model is expanded, observation selection will also enable you to assign it to a model instance." />
                </div>
                <div className="ReviewToolbar__actions">
                    <Tooltip title={<h3 className="Tooltip-title">Upload Observations</h3>} arrow>
                        <IconButton
                            className="ReviewToolbar__Button"
                            onClick={() => fileUpload.current.click()}
                            aria-label="Upload Observations"
                        >
                            <CloudUpload />
                            <input
                                id="upload"
                                className="upload"
                                type="file"
                                accept="*"
                                onInput={uploadObservations}
                                ref={fileUpload}
                            />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={<h3 className="Tooltip-title">Filter Observations</h3>} arrow>
                        <IconButton
                            className="ReviewToolbar__Button"
                            aria-label="Filter list"
                            onClick={(): void => setShow(!show)}
                        >
                            {show ? <Close /> : <FilterList />}
                        </IconButton>
                    </Tooltip>
                    <div ref={container} />
                    {show ? (
                        <Portal container={container.current}>
                            <div className="ReviewToolbar__actions-filterbBox">
                                <FilterDropDown handleTimeFilter={handleTimeFilter} filterItems={filterItems} />
                            </div>
                        </Portal>
                    ) : null}
                </div>
            </header>
        </aside>
    );
};

export default EnhancedTableToolbar;
