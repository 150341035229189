/* eslint-disable  */
import React, { ReactElement } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import lightGreen from '@material-ui/core/colors/lightGreen';
import orange from '@material-ui/core/colors/orange';

import { Drawer } from '@material-ui/core';
import { selectHelpExpand, selectHelpOpen, setHelpOpen } from 'app/appSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import DataOrgWorkspace from 'components/DataOrganization/DataOrgWorkspace';
import Help from 'components/Help/Help';
import NavBar from 'components/Nav/NavBar';
import Plotting from 'components/vis/Plotting';

import './App.scss';

const theme = createMuiTheme({
    palette: {
        primary: { main: '#016848' },
        secondary: { main: lightGreen[500] },
        error: orange,
        contrastThreshold: 3,
        tonalOffset: 0.5,
    },
    shape: {
        borderRadius: 4,
    },
});

// const alertOptions = {
//     timeout: 2000,
//     position: 'bottom center',
// };

const App = (): ReactElement => {
    const dispatch = useAppDispatch();
    const appHelp = useAppSelector(selectHelpOpen);
    const appHelpExpand = useAppSelector(selectHelpExpand);

    return (
        <>
            <CssBaseline />
            <MuiThemeProvider theme={theme}>
                <Router>
                    <div className="App">
                        <div className={`App${appHelp ? '__helpOpen' : ''}`}>
                            <NavBar />
                            <Route exact path="/" component={DataOrgWorkspace} />
                            <Route path="/data-analysis" component={Plotting} />
                        </div>
                        <aside title="Help Drawer">
                            <Drawer
                                anchor="right"
                                open={appHelp}
                                onClose={() => dispatch(setHelpOpen(false))}
                                className={`App__help${appHelpExpand ? '-expanded' : ''}`}
                                // variant={appHelpExpand ? 'temporary' : 'persistent'}
                                variant="persistent"
                            >
                                <Help />
                            </Drawer>
                        </aside>
                    </div>
                </Router>
            </MuiThemeProvider>
        </>
    );
};
export default App;
