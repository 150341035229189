import React, { ReactElement } from 'react';
import { Tooltip } from '@material-ui/core';
import { InfoOutlined } from '@material-ui/icons';

interface Props {
    title: string;
}

const Info = ({ title }: Props): ReactElement => (
    <Tooltip title={<h3 className="Tooltip-title">{title}</h3>} arrow>
        <InfoOutlined color="secondary" />
    </Tooltip>
);

export default Info;
